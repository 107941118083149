<template>
  <v-row no-gutters>
    <v-card
      cols="12"
      sm="12"
			v-show="carteiras.length"
			:title="item.car_nome"
      variant="outlined"
      rounded="lg"
      min-width="19%"
			class="mr-3 mt-3"
      v-for="(item, index) in carteiras"
      :key="index"
		>
      <v-card-text class="pb-0">
				<p>Saldo inicial: {{ formatter.floatToMoney(item.car_saldo_inicial) }}</p>
				<p>Data daldo inicial: {{ formatter.formatDate(item.car_data_saldo_inicial) }}</p>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-tooltip text="Editar" location="top">
					<template v-slot:activator="{ props }">
						<v-btn icon flat density="comfortable" @click="$emit('actions', 'edit', item.car_uuid, item.car_nome)">
							<v-icon v-bind="props" icon="mdi-pencil"></v-icon>
						</v-btn>
					</template>
				</v-tooltip>
				
				<v-tooltip text="Excluir" location="top">
					<template v-slot:activator="{ props }">
						<v-btn icon flat density="comfortable" @click="$emit('actions', 'delete', item.car_uuid, item.car_nome)">
							<v-icon v-bind="props" icon="mdi-delete"></v-icon>
						</v-btn>
					</template>
				</v-tooltip>
			</v-card-actions>
    </v-card>
		<v-card
			v-if="!carteiras.length"
      variant="outlined"
      rounded="lg"
			class="mt-2"
      width="300"
			height="130"
			:loading="loadingListagem"
		>
			<v-card-title v-if="!loadingListagem">Cadatre uma carteira!</v-card-title>
      <v-card-text  v-if="!loadingListagem">
				<p>Nenhuma carteira foi encontrada.</p>
			</v-card-text>
			<template v-slot:loader="{ isActive }">
				<v-row justify="center" class="mt-11" v-if="isActive">
					<v-progress-circular indeterminate></v-progress-circular>
				</v-row>
    </template>
    </v-card>
  </v-row>
</template>

<script>
import Api from "@/helpers/api.js";
import Formatters from "@/helpers/formatters.js";
export default {
  data() {
    return {
			formatter: Formatters,
      carteiras: [],
      loadingListagem: false,
    };
  },
  mounted() {
    this.getCarteiras();
  },
  methods: {
    getCarteiras() {
      this.loadingListagem = true;
      Api.get("carteiras")
        .then((res) => {
          this.carteiras = res.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        });
    },
  },
};
</script>
