<template>
  <div :key="keyChekin">

  <app-bar></app-bar>
  <v-row no-gutters justify="center" class="mt-12" v-if="!ingressoValidado">
    <v-form ref="formIngresso" @submit.prevent="verificaIngresso">
      <span class="text-h5">Insira o código do ingresso</span>
      <v-spacer></v-spacer>
      <v-otp-input ref="inputOTP" type="text" :length="8" v-model="codigo" :loading="loadingOTP"></v-otp-input>
      <v-btn rounded="xl" class="blue-background text-white px-4" type="submit" :loading="loadingOTP">Fazer check-in</v-btn>
    </v-form>
  </v-row>
  <v-form ref="formCheckin" @submit.prevent="confirmaCheckin"  v-else>
    <v-row no-gutters justify="center" class="mt-12">
      <v-col cols="12" sm="12" md="6">
        <v-btn flat class="mb-2" size="small" @click="dadosComprador()">Usar dados do comprador</v-btn>
        <v-text-field
          density="compact"
          bg-color="white"
          variant="outlined"
          prepend-inner-icon="mdi-account"
          label="Nome"
          :rules="rules.getMandatoryRule()"
          :loading="loadingSubmit"
          v-model="usuario.usu_nome"
        ></v-text-field>
        <v-text-field
          density="compact"
          bg-color="white"
          variant="outlined"
          prepend-inner-icon="mdi-email"
          label="Email"
          :rules="rules.getEmailRule()"
          v-model="usuario.usu_email"
          :loading="loadingSubmit"
          autocomplete="off"
        ></v-text-field>
        <v-text-field
          density="compact"
          bg-color="white"
          variant="outlined"
          :rules="rules.getPhoneRule()"
          class="my-1"
          type="text"
          label="Telefone"
          prepend-inner-icon="mdi-phone-outline"
          :loading="loadingSubmit"
          v-model="usuario.usu_telefone"
          v-mask-phone.br
        ></v-text-field>
        <v-text-field
          density="compact"
          bg-color="white"
          variant="outlined"
          class="my-1"
          label="Código"
          :loading="loadingSubmit"
          v-model="usuario.codigo"
          readonly
        ></v-text-field>
        <v-btn class="blue-background text-white" type="submit" :loading="loadingSubmit" :disabled="loadingSubmit" rounded>Confirmar check-in</v-btn>
      </v-col>
    </v-row>
  </v-form>
</div>

</template>

<script>
import Api from '@/helpers/api';
import { toast } from 'vue3-toastify';
import AppBar from "./AppBar.vue";
import Rules from "@/helpers/rules"
export default {
  components: {
    AppBar
  },
  data() {
    return {
      rules: Rules,
      loadingOTP: false,
      loadingSubmit: false,
      ingressoValidado: false,
      codigo: '',
      usuario: {
        usu_nome: "",
        usu_email: "",
        usu_telefone: "",
        codigo: ""
      },
      comprador: [],
      keyChekin: 0
    }
  },
  watch: {
    codigo(code) {
      this.codigo = code.toUpperCase();
    }
  },
  mounted () {
    this.$refs.inputOTP.focus();
  },
  methods: {
    dadosComprador() {
      this.usuario = this.comprador
    },
    verificaIngresso() {
      if (this.codigo.length < 8) {
        this.$refs.inputOTP.focus();
        return
      }
      this.loadingOTP = true;

      Api.get("checkin/verificaIngresso?codigo=" + this.codigo)
        .then(res => {
          this.comprador = res.data.message
          this.usuario.codigo = this.comprador.codigo
          this.ingressoValidado = true;
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.response.data.message, {
            position: `bottom-center`
          });
          this.$refs.inputOTP.focus();
        })
        .finally(() => {
          this.loadingOTP = false;
        });
    },
    confirmaCheckin() {
      this.$refs.formCheckin.validate();
      if (!this.$refs.formCheckin.isValid) return this.loadingSubmit = false;
      this.loadingSubmit = true;
      Api.post("checkin/confirmaCheckin", this.usuario)
        .then(() => {
          toast.success('Check-in feito com sucesso!', {
            position: `bottom-center`
          });
          this.ingressoValidado = false,
          this.codigo = '';
          this.usuario = {
            usu_nome: "",
            usu_email: "",
            usu_telefone: "",
            codigo: ""
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.response.data.message, {
            position: `bottom-center`
          });
        })
        .finally(() => {
          this.loadingSubmit = false;
        });
    },
  },
}
</script>