<template>
  <div class="gradient d-flex flex-column align-center justify-space-around" position="absolute">
    <div>
      <img :src="require('../assets/ndpz_logo.svg')" height="80" />
      <p class="blue-color text-center">Crescer e transformar</p>
    </div>
    <v-container class="d-flex flex-column align-center justify-space-around">
      <v-card
        rounded="xl"
        class="yellow-background mb-7"
        max-width="1000"
        width="80%"
        min-height="300"
      >
        <v-row
          no-gutters
          align="center"
          justify="space-between"
          class="pa-12"
          style="height: 100%"
        >
          <v-col md="5" xl="5" cols="12" sm="12">
            <div class="text-h6 text-center">
              <p>
                Bem-vindo(a) ao sistema da <span class="blue-color">NDPZ</span>!
              </p>
              Faça o login para começar.
            </div>
          </v-col>
          <v-col md="5" xl="5" cols="12" sm="12">
            <v-form fast-fail ref="formLogin" @submit.prevent="login">
              <v-text-field
                class="mb-1"
                single-line
                density="compact"
                bg-color="white"
                variant="outlined"
                prepend-inner-icon="mdi-email"
                label="Email"
                type="email"
                :rules="rules.getEmailRule()"
                v-model="user.email"
              ></v-text-field>
              <v-text-field
                single-line
                density="compact"
                bg-color="white"
                variant="outlined"
                prepend-inner-icon="mdi-lock"
                :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="showPassword = !showPassword"
                v-model="user.password"
                label="Senha"
                :type="!showPassword ? 'password' : 'text'"
                :rules="rules.getTextLengthRule(6)"
              ></v-text-field>
              <v-btn type="submit" block class="mt-2 blue-background text-white" :loading="loadingSubmit">
								Entrar
							</v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Rules from "@/helpers/rules.js";
import Login from "@/helpers/login.js";
import Api from "@/helpers/api.js";
import { toast } from 'vue3-toastify';
export default {
  data() {
    return {
      rules: Rules,
      showPassword: false,
      formLogin: false,
      loadingSubmit: false,
      user: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async login(event) {
      this.loadingSubmit = true;
      const results = await event;
      if (!results.valid) return this.loadingSubmit = false;
      await Api.post("sistema/login", this.user)
        .then((response) => {
          Login.saveToken(response.data.authorisation.token, 'admin');
          this.$router.go('Lancamentos');
        })
        .catch(err => {
          toast.error(err.response.data.message)
        })
        .finally(()=> {
          this.loadingSubmit = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/_variables.sass";

.gradient {
  height: 100%;
  background: linear-gradient(0deg, $blue 30%, white 30%);
}
</style>
