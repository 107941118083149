// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import ptBR from 'date-fns/locale/pt-BR'
import DateFnsAdapter from '@date-io/date-fns'

// Vuetify
import { createVuetify } from 'vuetify'
import { VDataTable } from 'vuetify/components/VDataTable'
import { pt } from 'vuetify/locale'

const ndpzTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#00017a', // azul
    'primary-darken-1': '#3700B3',
    secondary: '#FFBA00', // amarelo
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  }
}

export default createVuetify({
  date: {
    adapter: DateFnsAdapter,
    locale: {
      pt: ptBR,
    },
  },
  locale: {
    locale: 'pt',
    fallback: 'pt',
    messages: { pt }
  },
  theme: {
    defaultTheme: 'ndpzTheme',
    themes: {
      ndpzTheme,
    }
  },
  components: {
    VDataTable,
  },
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
})
