<template>
  <app-bar></app-bar>
  <v-container>
    <v-row dense>
      <v-col v-for="(item, index) in evento" :key="index" cols="12" sm="6" md="4">
        <v-card
          border
          class="mb-2"
          density="compact"
          :subtitle="item.evento.eve_local"
          :title="item.evento.eve_titulo"
          variant="text"
        >
          <v-img :src="`https://ndpz-app-public.s3.us-west-1.amazonaws.com/${item.evento.eve_imagem}`" ></v-img>

          <v-card-text>
            <p v-if="item.evc_confirmado == 0">Pagamento vencido</p>
            <p v-if="item.evc_confirmado == 1">Pagamento pendente</p>
            <p v-if="item.evc_confirmado == 2">Pagamento confirmado</p>
            <h4>Ingressos</h4>
            <p v-for="ing in item.ingressos" :key="ing.evi_uuid">
              {{ ing.evi_nome }} - {{ ing.evp_codigo }}
              <span class="text-red text-right text-caption" v-if="ing.evp_checkin">(Check-in feito)</span>
            </p>
          </v-card-text>

          <template v-slot:actions>
            <v-btn class="blue-background text-white" :to="`/eventos/compras/detalhes/${item.evento.eve_uuid}`">Ver detalhes</v-btn>
            <v-spacer></v-spacer>
            <v-btn class="blue-background text-white" :href="item.asaas_pdf_url" target="_blank" v-if="item.evc_confirmado != 2">Boleto</v-btn>
            <v-btn class="blue-background text-white" :href="item.asaas_url" target="_blank">Cobrança</v-btn>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense v-if="!evento.length">
      <v-col class="text-center mt-4">
        <h3>Oops! Nenhum ingresso disponível!</h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from '@/helpers/api';
import AppBar from "./AppBar.vue";
export default {
  components: {
    AppBar
  },
  data() {
    return {
      evento: [],
      loadingListagem: false,
      show: false
    }
  },
  mounted () {
    this.getEventos();
  },
  methods: {
    getEventos() {
      this.loadingListagem = true;
      Api.get("compras/meusEventos")
        .then((res) => {
          this.evento = res.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        });
    },
  }
}
</script>