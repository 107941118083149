<template>
  <div>
    <v-card>
      <v-card-title class="yellow-background">
        <v-row no-gutters align="center">
          <span class="text-h5">{{ cardTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn icon flat density="comfortable" @click="emitClose()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-form fast-fail ref="formCategoria" @submit.prevent="saveCategoria" :disabled="action == 'read'">
        <v-card-text>
          <v-row dense>
            <v-col class="mb-2">
              <v-btn icon :color="categoria.cat_cor" @click="dialogColorPicker = true" :loading="loadingCadastro">
                <v-icon>mdi-format-color-fill</v-icon>
              </v-btn>
              <span class="text-grey-darken-2 ml-2">{{ categoria.cat_cor ? categoria.cat_cor : 'Selecionar cor' }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                :loading="loadingCadastro"
                type="text"
                label="Nome*"
                :rules="rules.getMandatoryRule()"
                v-model="categoria.cat_nome"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-select
                :disabled="action == 'edit'"
                class="text-capitalize"
                density="compact"
                bg-color="white"
                variant="outlined"
                type="text"
                label="Tipo*"
                :loading="loadingCadastro"
                :items="itemsTipos"
                v-model="categoria.cat_tipo"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="action != 'read'">
          <v-btn rounded="xl" @click="emitClose()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded="xl" class="blue-background text-white px-4" type="submit" :disabled="disableFields">salvar</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn rounded="xl" @click="emitClose()">Fechar</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-dialog width="330" v-model="dialogColorPicker">
      <v-card>
        <v-card-title class="yellow-background"> Selecione uma cor </v-card-title>
        <v-card-text class="pb-0">
          <v-color-picker
            v-model="categoria.cat_cor"
            elevation="0"
            hide-inputs
            show-swatches
            :swatches="itemsColor"
          ></v-color-picker>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn rounded="xl" @click="dialogColorPicker = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/helpers/api';
import Rules from '@/helpers/rules.js';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  props: {
    cat_uuid: String,
    action: String,
  },
  data() {
    return {
      rules: Rules,
      loadingCadastro: false,
      dialogColorPicker: false,
      itemsColor: [
        ['#F44336', '#E91E63', '#9C27B0', '#673AB7' ],
        ['#3F51B5', '#2196F3', '#00BCD4', '#009688' ],
        ['#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B' ],
        ['#FFC107', '#FF9800', '#FF5722'],
        ['#795548', '#607D8B', '#FF4081']
      ],
      disableFields: false,
      cardTitle: "Nova Categoria",
      itemsTipos: [
        { title: 'Receita', value: '1' },
        { title: 'Despesa', value: '2' },
        { title: 'Transferência', value: '3' },
      ],
      categoria: {
        cat_nome: ``,
        cat_tipo: '1',
        cat_cor: '#F0F0F0'
      }
    };
  },
  mounted() {
    if (this.action != "create") this.getCategoria();
    if (this.action == "edit") this.cardTitle = "Editar Categoria";
    if (this.action == "read") this.cardTitle = "Ver Categoria";
  },
  methods: {
    emitClose () {
      this.$emit('fechaDialog');
    },
    async saveCategoria (event) {
      this.disableFields = true;
      const results = await event
      if (!results.valid) {
        this.disableFields = false;
        toast.info("Preencha todos os campos!", {
          position: `bottom-center`
        })
        return
      }
      let notificacao = toast.loading("Salvando carteira");
      let rota = 'novo';
      if (this.action == 'edit') rota = 'editar'
      Api.post(`categorias/${rota}`, this.categoria)
        .then(res => {
          toast.update(notificacao, {
            render: res.data.message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
          })
          this.emitClose();
        })
        .catch(err => {
          toast.update(notificacao, {
            render: err.response.data.message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
          })
        })
      setTimeout(() => {
        this.disableFields = false;
      }, 1000);
    },
    getCategoria() {
      if (!this.cat_uuid) return
      this.loadingCadastro = true
      Api.post('categorias/buscar', { cat_uuid: this.cat_uuid })
        .then(res => {
          this.categoria = res.data
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCadastro = false;
        })
    },
  },
};
</script>