<template>
  <v-card variant="outlined" rounded="lg" color="grey">
    <v-card-text>
      <v-row no-gutters align="center">
        <div>
          <span class="text-body-1 text-black">Total do periodo:</span>
          <span class="ml-1 font-weight-bold text-body-1" :class="totalPeriodo() >= 0 ? 'text-success' : 'text-red'">{{ formatters.floatToMoney(totalPeriodo())}}</span>
        </div>
        <v-spacer></v-spacer>
        <div>
          <VueDatePicker
            range
            :enable-time-picker="false"
            locale="pt-BR"
            :day-class="getDayClass"
            text-input
            :text-input-options="textInputOptions"
            :alt-position="customPosition"
            v-model="dateRange"
            :clearable="false"
            model-type="yyyy-MM-dd"
            @update:model-value="getLancamentos()"
          >
           <template #action-row="{ selectDate }">
              <v-spacer></v-spacer>
              <v-btn @click="selectDate" color="primary" rounded="xl">
                Confirmar
              </v-btn>
            </template>
            <template #trigger>
              <v-chip class="text-black pointer">
                <v-icon>mdi-filter</v-icon>
                Período: 
                {{ formatters.formatDate(dateRange[0], 'LL') }} até
                {{ formatters.formatDate(dateRange[1], 'LL') }}
              </v-chip>
            </template>
            <template #left-sidebar>
              <div class="mb-2">Datas personalizadas</div>
              <v-divider color="black"></v-divider>
              <div class="py-2 text-black pointer" @click="customDatas('hoje')">Hoje</div>
              <div class="py-2 text-black pointer" @click="customDatas('week')">Essa semana</div>
              <div class="py-2 text-black pointer" @click="customDatas('month')">Este mês</div>
              <div class="py-2 text-black pointer" @click="customDatas('year')">Este ano</div>
            </template>
          </VueDatePicker>
        </div>
      </v-row>
      <v-expansion-panels multiple v-model="allOpen" readonly class="mt-4">
        <v-expansion-panel
          elevation="0"
          v-for="(item, index) in diasLancamentos"
          :key="index"
        >
          <v-expansion-panel-title collapse-icon="">
            <p>{{ formatters.formatDate(item, 'LL') }}</p>
            <v-spacer></v-spacer>
            Total do dia: 
            <span class="font-weight-bold ml-2 text-body-1" :class="totalDiario(index) >= 0 ? 'text-success' : 'text-red'">{{ formatters.floatToMoney(totalDiario(index)) }}</span>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-data-iterator
              :items="lancamentos[index]"
              item-value="lan_uuid"
              :items-per-page="2000"
            >
            <template v-slot:default="{ items, isExpanded, toggleExpand }">
              <v-row>
                <v-col
                  v-for="item in items"
                  :key="item.raw.lan_uuid"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-card elevation="0">
                    <v-card-title>
                      <v-row no-gutters justify="space-between" align="center">
                        <!-- Chip tipo lancamento -->
                        <v-col>
                          <div class="d-flex" >
                            <v-chip v-if="item.raw.lan_tipo == 'receita'" class="text-capitalize" prepend-icon="mdi-plus" color="green">{{item.raw.lan_tipo}}</v-chip>
                            <v-chip v-else-if="item.raw.lan_tipo == 'despesa'" class="text-capitalize" prepend-icon="mdi-minus" color="red">{{item.raw.lan_tipo}}</v-chip>
                            <v-chip v-else-if="item.raw.lan_tipo == 'transferencia'" class="text-capitalize" prepend-icon="mdi-swap-horizontal" color="purple">Transferência</v-chip>
                            <h5 class="ml-2">{{ item.raw.lan_descricao }}</h5>
                            <v-chip size="small" v-if="item.raw.lan_repeticao == '2'" class="ml-2">{{ item.raw.lan_numero_parcela }}/{{ item.raw.lan_parcelas }}</v-chip>
                          </div>
                        </v-col>
                        <!--  -->
                        
                        <!-- Despesas -->
                        <v-col>

                          <div class="text-center">
                            <span class="text-body-1 font-weight-bold text-red" v-if="!item.raw.despesas.length">
                            {{ calcularDespesas(item.raw) }}
                          </span>
                          <v-tooltip location="start" color="success" v-else>
                            <template v-slot:activator="{ props }">
                              <span class="text-body-1 font-weight-bold text-red" v-bind="props">
                                {{ calcularDespesas(item.raw) }}
                              </span>
                            </template>
                            <p v-for="(despesa, ind) in item.raw.despesas" :key="ind">
                              {{ despesa.lde_descricao }} - {{ despesa.lde_tipo == 1 ? formatters.floatToMoney(despesa.lde_valor) : formatters.floatToMoney((Number(despesa.lde_valor)/100)*(item.raw.lan_valor_bruto)) }}
                            </p>
                          </v-tooltip>
                        </div>
                        </v-col>
                        <v-col>
                          <div class="text-right">
                            <span :class="item.raw.lan_tipo == 'receita' ? 'text-green' : item.raw.lan_tipo == 'despesa' ? 'text-red' : 'text-purple'" class="text-body-1 font-weight-bold mr-2">{{ formatters.floatToMoney(item.raw.lan_valor_bruto) }}</span>
                          </div>
                        </v-col>
                        <!--  -->
                      </v-row>
                    </v-card-title>

                    <div class="px-4">
                      <v-row no-gutters>
                        <!-- Mostrar detalhes -->
                        <v-switch
                          :model-value="isExpanded(item)"
                          :label="`${isExpanded(item) ? 'Esconder' : 'Mostrar'} detalhes`"
                          density="compact"
                          @click="() => toggleExpand(item)"
                          color="secondary"
                        ></v-switch>
                        <!--  -->
                        <v-spacer></v-spacer>
                        <!-- Acoes -->
                        <v-tooltip text="Editar" location="top">
                          <template v-slot:activator="{ props }">
                            <v-btn icon flat density="comfortable" @click="$emit('actions', 'edit', item.raw.lan_uuid, item.raw.lan_descricao, item.raw.lan_tipo)">
                              <v-icon v-bind="props" icon="mdi-pencil"></v-icon>
                            </v-btn>
                          </template>
                        </v-tooltip>
                        
                        <v-tooltip text="Excluir" location="top">
                          <template v-slot:activator="{ props }">
                            <v-btn icon flat density="comfortable" @click="$emit('actions', 'delete', item.raw.lan_uuid, item.raw.lan_descricao, item.raw.lan_tipo)">
                              <v-icon v-bind="props" icon="mdi-delete"></v-icon>
                            </v-btn>
                          </template>
                        </v-tooltip>
                        <!--  -->
                      </v-row>
                    </div>

                    <v-divider></v-divider>

                    <v-expand-transition>
                      <div v-if="isExpanded(item)">
                        <v-list density="compact" :lines="false">
                          <!-- Linha 1 - Detalhes expandidos -->
                          <v-list-item>
                            <v-row no-gutters>
                              <v-col>
                                <div>
                                  <span v-if="item.raw.lan_confirmado == 1">
                                    <v-icon color="green" class="mr-2">mdi-check</v-icon>
                                    Confirmado
                                  </span>
                                  <span v-else>
                                    <v-icon color="red" class="mr-2">mdi-close</v-icon>
                                    Não confirmado
                                  </span>
                                </div>
                                <div>
                                  <v-icon class="mr-2">mdi-format-list-bulleted</v-icon>
                                  Categoria: {{ item.raw.categoria?.cat_nome || 'Não cadastrado' }}
                                </div>
                              </v-col>
                            
                              <v-col>
                                <div>
                                  <v-icon class="mr-2">mdi-rotate-3d-variant</v-icon>
                                  Repetição: {{ itemsRepeticao[item.raw.lan_repeticao-1] || 'Não cadastrado' }}
                                </div>
                                <div>
                                  <v-icon class="mr-2">mdi-wallet</v-icon>
                                  Carteira: {{ item.raw.carteira?.car_nome || 'Não cadastrado' }}
                                </div>
                              </v-col>
                          
                              <v-col>
                                <div>
                                  <v-icon class="mr-2" >mdi-file</v-icon>
                                  Arquivos: {{ item.raw.arquivos.length || 0 }}
                                </div>
                                <div>
                                  <v-icon class="mr-2">mdi-account-multiple</v-icon>
                                  Cliente: {{ item.raw.cliente?.cli_nome || 'Não cadastrado' }}
                                </div>
                              </v-col>
                            </v-row>
                          </v-list-item>
                          <!--  -->
                        </v-list>
                      </div>
                    </v-expand-transition>
                    <v-divider></v-divider>
                  </v-card>
                </v-col>
              </v-row>
              </template>
            </v-data-iterator>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-row v-show="!diasLancamentos.length && !loadingListagem" no-gutters justify="center">
        <span class="mt-2 text-grey-darken-2">Nenhum lançamento encontrado!</span>
      </v-row>
      <v-row v-show="!diasLancamentos.length && loadingListagem" no-gutters justify="center">
        <v-progress-circular indeterminate class="mt-3" width="5" size="37" color="primary"></v-progress-circular>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Api from "@/helpers/api.js";
import Formatters from '@/helpers/formatters';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { addDays, isEqual, set } from 'date-fns';
import { ref } from 'vue';
import moment from 'moment'
export default {
  components: {
    VueDatePicker
  },
  data() {
    return {
      itemsRepeticao: ["Única", "Parcelada"],
      dateRange: [],
      allOpen: [],
      diasLancamentos: [],
      formatters: Formatters,
      lancamentos: [],
      loadingListagem: false,
      getDayClass: (date) => {
        if (isEqual(date, addDays(set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }), 1)))
          return 'marked-cell';
        return '';
      },
      customPosition: () => ({ top: 193, left: 950, }),
      textInputOptions: ref({
        format: 'dd/MM/yyyy'
      }),
    };
  },
  mounted() {
    let startDate = this.$route.query.data_inicial ? this.$route.query.data_inicial : moment(new Date()).subtract(1, 'months').format('yyyy-MM-DD');
    let endDate = this.$route.query.data_final ? this.$route.query.data_final : moment(new Date()).format('yyyy-MM-DD');
    this.dateRange = [startDate, endDate];
    this.getLancamentos();
  },
  methods: {
    customDatas(data) {
      if (data == 'hoje') {
        let today = moment(new Date()).format('yyyy-MM-DD');
        this.dateRange = [today, today];
      } else {
        let start = moment().startOf(data).format('yyyy-MM-DD');
        let end = moment().endOf(data).format('yyyy-MM-DD');
        this.dateRange = [start, end];
      }

    },
    getLancamentos() {
      this.loadingListagem = true;
      this.$router.replace({ name: `Lancamentos`, query: { data_inicial: this.dateRange[0], data_final: this.dateRange[1]}});

      Api.get(`lancamentos?data_inicial=${this.dateRange[0]}&data_final=${this.dateRange[1]}`)
        .then((res) => {
          this.lancamentos = Object.values(res.data);
          this.diasLancamentos = Object.keys(res.data);
          this.allOpen = Array.from(Array(this.diasLancamentos.length).keys())
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        });
    },
    totalPeriodo() {
      let total = 0;
      this.lancamentos.forEach(lancamentos => {
        lancamentos.forEach(lan => {
          if (lan.lan_tipo == 'receita') {
            total += (lan.lan_valor_bruto - lan.totalDespesa);
          } else if (lan.lan_tipo == 'despesa') {
            total -= lan.lan_valor_bruto;
          }
        });
      });
      return total
    },
    totalDiario(index) {
      let total = 0;
      this.lancamentos[index].forEach(lan => {
        if (lan.lan_tipo == 'receita') {
          total += (lan.lan_valor_bruto - lan.totalDespesa);
        } else if (lan.lan_tipo == 'despesa') {
          total -= lan.lan_valor_bruto;
        }
      });
      return total
    },
    calcularDespesas(item) {
      let totalDespesa = 0;
      item.despesas.forEach(despesa => {
        if (despesa.lde_tipo == 1) {
          totalDespesa += Formatters.moneyToFloat(despesa.lde_valor)
        } else {
          totalDespesa += (Formatters.moneyToFloat(despesa.lde_valor)/100)*(Formatters.moneyToFloat(item.lan_valor_bruto))
        }
      });
      return totalDespesa > 0 ? Formatters.floatToMoney(totalDespesa) : '-';
    }
  },
};
</script>

<style scoped>
.v-tooltip >>> .v-overlay__content {
    background: rgba(0, 0, 0, 0.87) !important;
}
.pointer {
  cursor: pointer;
}
</style>