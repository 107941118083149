<template>
    <div>
      <v-card>
        <v-card-title class="yellow-background">
          <v-row no-gutters align="center">
            <span class="text-h5">{{ cardTitle }}</span>
            <v-spacer></v-spacer>
            <v-btn icon flat density="comfortable" @click="emitClose()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-form fast-fail ref="formCarteira" @submit.prevent="saveCarteira" :disabled="action == 'read'">
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  density="compact"
                  bg-color="white"
                  variant="outlined"
                  :loading="loadingCadastro"
                  type="text"
                  label="Nome*"
                  :rules="rules.getMandatoryRule()"
                  v-model="carteira.car_nome"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  density="compact"
                  bg-color="white"
                  variant="outlined"
                  type="text"
                  label="Saldo inicial*"
                  :loading="loadingCadastro"
                  v-model="car_saldo_inicial"
                  v-mask-decimal.br="2"
                  prefix="R$"
                  :rules="rules.getMandatoryRule()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <VueDatePicker
                  text-input
                  :text-input-options="textInputOptions"
                  v-mask-date.br
                  ref="dp"
                  placeholder="Data"
                  :alt-position="customPosition"
                  :clearable="false"
                  :day-class="getDayClass"
                  locale="pt-BR"
                  :enable-time-picker="false"
                  v-model="carteira.car_data_saldo_inicial"
                  :format="format"
                >
                  <template #action-buttons>
                    <v-btn @click="selectDate" color="primary" rounded="xl">
                      Confirmar
                    </v-btn>
                  </template>
                </VueDatePicker>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions v-if="action != 'read'">
            <v-btn rounded="xl" @click="emitClose()">Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn rounded="xl" class="blue-background text-white px-4" type="submit" :disabled="disableFields">salvar</v-btn>
          </v-card-actions>
          <v-card-actions v-else>
            <v-spacer></v-spacer>
            <v-btn rounded="xl" @click="emitClose()">Fechar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </div>
  </template>
  
<script>
import Api from '@/helpers/api';
import Rules from '@/helpers/rules.js';
import moment from 'moment';
import formatters from '@/helpers/formatters';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
  export default {
    components: {
      VueDatePicker
    },
    props: {
      car_uuid: String,
      action: String,
    },
    data() {
      return {
        rules: Rules,
        loadingCadastro: false,
        disableFields: false,
        cardTitle: "Nova Carteira",
        car_saldo_inicial: 0,
        carteira: {
          car_nome: '',
          car_saldo_inicial: 0,
          car_data_saldo_inicial: moment().format('YYYY-MM-DD HH:mm:ss')
        }
      };
    },
    mounted() {
      if (this.action != "create") this.getCarteira();
      if (this.action == "edit") this.cardTitle = "Editar Carteira";
      if (this.action == "read") this.cardTitle = "Ver Carteira";
    },
    methods: {
      emitClose () {
        this.$emit('fechaDialog');
      },
      async saveCarteira (event) {
        this.disableFields = true;
        const results = await event
        if (!results.valid) {
          this.disableFields = false;
          toast.info("Preencha todos os campos!", {
            position: `bottom-center`
          })
          return
        }
        let notificacao = toast.loading("Salvando carteira");
        let rota = 'novo';
        if (this.action == 'edit') rota = 'editar'
        this.carteira.car_saldo_inicial = formatters.moneyToFloat(this.car_saldo_inicial);
        Api.post(`carteiras/${rota}`, this.carteira)
          .then(res => {
            toast.update(notificacao, {
              render: res.data.message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              type: 'success',
              isLoading: false,
            })
            this.emitClose();
          })
          .catch(err => {
            toast.update(notificacao, {
              render: err.response.data.message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              type: 'error',
              isLoading: false,
            })
          })
        setTimeout(() => {
          this.disableFields = false;
        }, 1000);
      },
      getCarteira() {
        if (!this.car_uuid) return
        this.loadingCadastro = true
        Api.post('carteiras/buscar', { car_uuid: this.car_uuid })
          .then(res => {
            this.carteira = res.data
            this.car_saldo_inicial = formatters.floatToMoney(this.carteira.car_saldo_inicial);
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            this.loadingCadastro = false;
          })
      },
    },
  };
  </script>

<style>
.dp__menu {
    position: fixed;
    background: var(--dp-background-color);
    border-radius: var(--dp-border-radius);
    min-width: var(--dp-menu-min-width);
    font-family: var(--dp-font-family);
    font-size: var(--dp-font-size);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid var(--dp-menu-border-color);
    box-sizing: border-box;
} 
.custom-select {
  cursor: pointer;
  color: var(--vp-c-text-2);
  margin: 0;
  display: inline-block;
}
</style>

<script setup>
// Example using a custom format function
import { addDays, isEqual, set } from 'date-fns';
import { ref } from 'vue';

const dp = ref();
const selectDate = () => {
  dp.value.selectDate()
}

const getDayClass = (date) => {
  if (isEqual(date, addDays(set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }), 1)))
    return 'marked-cell';
  return '';
};

const customPosition = () => ({ top: 183, left: 202, });

const textInputOptions = ref({
  format: 'dd/MM/yyyy'
})

const format = (date) => {
  const day = date.getDate() < 10 ? '0'+ date.getDate() : date.getDate();
  const month = date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}
</script>