<template>
  <v-container fluid>
    <v-row no-gutters>
      <span class="text-h4">Relatorios</span>
      <v-spacer></v-spacer>
      <v-btn rounded="xl" class="blue-background text-white mr-3" to="/lancamentos">
        <v-icon>mdi-arrow-left</v-icon>
        Voltar
      </v-btn>
      <v-btn rounded="xl" class="blue-background text-white" @click="actions()">Novo Relatorio</v-btn>
    </v-row>
    <v-row class="mt-1">
      <v-col>
        <relatorios-listagem @actions="actions" :key="keyListagem"></relatorios-listagem>
      </v-col>
    </v-row>
  </v-container>
  <v-row justify="center">
    <v-dialog v-model="dialogCadastro" persistent width="468">
      <relatorios-cadastro :action="actionCadastro" @fechaDialog="fecharDialog" :rel_uuid="rel_uuid" :key="dialogCadastro"></relatorios-cadastro>
    </v-dialog>
  </v-row>
  <delete-card :titleCard="rel_nome" :abrirDialog="dialogDelete" :key="keyDelete" @fecharDialogDelete="fecharDialog" @confirmDelete="deleteRelatorio"></delete-card>
</template>

<script>
import RelatoriosListagem from '@/components/lancamentos/relatorios/RelatoriosListagem.vue';
import RelatoriosCadastro from '@/components/lancamentos/relatorios/RelatoriosCadastro.vue';
import DeleteCard from '@/components/DeleteCard.vue';
import Api from '@/helpers/api';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  components: {
    RelatoriosListagem,
    RelatoriosCadastro,
    DeleteCard
  },
  data() {
    return {
      dialogCadastro: false,
      dialogDelete: false,
      rel_uuid: null,
      rel_nome: null,
      actionCadastro: null,
      keyListagem: 0,
      keyDelete: 0
    }
  },
  methods: {
    deleteRelatorio () {
      if (!this.rel_uuid) return
      Api.post('relatorios/excluir', { rel_uuid: this.rel_uuid })
        .then(res => {
          toast.success(res.data.message)
        })
        .catch(err => {
          toast.error(err.response.data.message)
        })
        .finally(() => {
          this.fecharDialog();
        })
    },
    fecharDialog () {
      this.keyListagem++;
      this.rel_uuid = null;
      this.rel_nome = null;
      this.dialogDelete = false;
      this.dialogCadastro = false;
    },
    actions (action='create', uuid=null, nome=null) {
      this.actionCadastro = action;
      this.rel_uuid = uuid;
      this.rel_nome = nome;
      if (action == 'delete') {
        this.dialogDelete = true;
        this.keyDelete++
        return
      }
      this.dialogCadastro = true;
    }
  }
}
</script>
