<template>
  <v-container fluid>
    <v-row no-gutters>
      <span class="text-h4">Lançamentos</span>
      <v-spacer></v-spacer>
      <v-btn rounded="xl" class="blue-background text-white mr-6" :loading="disableRelatorio" :disabled="disableRelatorio" to="/lancamentos/relatorios">
        Relatórios
        <v-icon class="ml-1">mdi-table</v-icon>
      </v-btn>
      <v-btn rounded="xl" class="blue-background text-white" @click="actions()">Novo Lançamento</v-btn>
    </v-row>
    <v-row class="mt-1">
      <v-col>
        <lancamentos-listagem @actions="actions" :key="keyListagem"></lancamentos-listagem>
      </v-col>
    </v-row>
  </v-container>
  <v-row justify="center">
    <v-dialog v-model="dialogCadastro" persistent width="868">
      <lancamentos-cadastro :action="actionCadastro" @fechaDialog="fecharDialog" :lan_tipo="lan_tipo" :lan_uuid="lan_uuid" :key="dialogCadastro"></lancamentos-cadastro>
    </v-dialog>
  </v-row>
  <delete-card :titleCard="lan_nome" :abrirDialog="dialogDelete" :key="keyDelete" @fecharDialogDelete="fecharDialog" @confirmDelete="deleteLancamento"></delete-card>
</template>

<script>
import LancamentosListagem from '@/components/lancamentos/LancamentosListagem.vue';
import LancamentosCadastro from '@/components/lancamentos/LancamentosCadastro.vue';
import DeleteCard from '@/components/DeleteCard.vue';
import Api from '@/helpers/api';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import moment from 'moment';
export default {
  components: {
    LancamentosListagem,
    LancamentosCadastro,
    DeleteCard
  },
  data() {
    return {
      dialogCadastro: false,
      dialogDelete: false,
      disableRelatorio: false,
      lan_uuid: null,
      lan_nome: null,
      lan_tipo: null,
      actionCadastro: null,
      keyListagem: 0,
      keyDelete: 0,
    }
  },
  methods: {
    deleteLancamento () {
      if (!this.lan_uuid) return
      Api.post('lancamentos/excluir', { lan_uuid: this.lan_uuid })
        .then(res => {
          toast.success(res.data.message)
        })
        .catch(err => {
          toast.error(err.response.data.message)
        })
        .finally(() => {
          this.fecharDialog();
        })
    },
    fecharDialog () {
      this.keyListagem++;
      this.lan_uuid = null;
      this.lan_nome = null;
      this.dialogDelete = false;
      this.dialogCadastro = false;
    },
    actions (action='create', uuid=null, nome=null, tipo=null) {
      this.actionCadastro = action;
      this.lan_uuid = uuid;
      this.lan_nome = nome;
      this.lan_tipo = tipo;
      if (action == 'delete') {
        this.dialogDelete = true;
        this.keyDelete++
        return
      } 
      this.dialogCadastro = true;
    },
    gerarRelatorio() {
      this.disableRelatorio = true;
      let startDate = this.$route.query.data_inicial ? this.$route.query.data_inicial : moment(new Date()).subtract(1, 'months').format('yyyy-MM-DD');
      let endDate = this.$route.query.data_final ? this.$route.query.data_final : moment(new Date()).format('yyyy-MM-DD');
      Api.getBlob(`lancamentos/relatorio?data_inicial=${startDate}&data_final=${endDate}`)
        .then(res => {
          toast.success('Relatório gerado!');
          let arquivoNome = 'Relatório_' + moment().format('L_H:mm') + '.pdf';
          var arquivoURL = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
          var arquivoLink = document.createElement('a');
          arquivoLink.href = arquivoURL;
          arquivoLink.setAttribute('download', arquivoNome);
          document.body.appendChild(arquivoLink);
          arquivoLink.click();
        })
        .catch(err => {
          toast.error(err.response.data.message)
        })
        .finally(() => {
          this.disableRelatorio = false;
        })
    }
  }
}
</script>
