<template>
  <app-bar></app-bar>
  <v-row dense justify="center" class="py-5">
    <v-img max-height="400" max-width="900" :src="`https://ndpz-app-public.s3.us-west-1.amazonaws.com/${evento.eve_imagem}`" :lazy-src="require('@/assets/ndpz_logo.svg')">
      <template v-slot:error>
        <v-img
          max-height="400"
          :src="require('@/assets/ndpz_logo.svg')"
        ></v-img>
      </template>
    </v-img>
  </v-row>
  <v-container>
    <v-btn rounded="xl" class="blue-background text-white px-4" :to="`/eventos/compras/ingressos/${this.$route.params.eve_uuid}`">Comprar ingresso</v-btn>
    <v-row dense>
      <v-col cols="12" sm="12" md="12">
        <h1 class="text-center">{{ evento.eve_titulo }}</h1>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <p>
          <v-row no-gutters>
            <v-col>
              <b>Local:</b> {{ evento.eve_local }}
            </v-col>
            <v-col class="text-right">
              <v-btn size="small" class="blue-background text-white" @click="loc()">
                Ver localização
              </v-btn>
            </v-col>
          </v-row>
        </p>
      </v-col>
      <v-divider class="my-4"></v-divider>
      <v-col cols="12" sm="12" md="12">
        <p>
          <b>Datas:</b> <span v-html="formataData(evento.datas)"></span> 
        </p>
      </v-col>
      <v-divider class="my-4"></v-divider>
      <v-col cols="12" sm="12" md="12">
        <p>
          <b>Descrição:</b> 
        </p>
        <span v-html="evento.eve_descricao"></span>
      </v-col>
      <v-divider class="my-4"></v-divider>
      <v-col cols="12" sm="12" md="12" v-if="evento.endereco.end_cep">
        <p>
          <b>Endereço:</b>
        </p>
        <p>{{ evento.endereco.end_rua }}, {{ evento.endereco.end_complemento }}</p>
        <p>{{ evento.endereco.end_bairro }}, {{ evento.endereco.end_cidade }}</p>
        <p>{{ evento.endereco.end_estado }}, {{ evento.endereco.end_cep }}</p>
        <v-btn
          ref="location"
          class="blue-background text-white mt-4"
          target="_blank"
          :href="encodeURI(`http://www.google.com/maps/search/${evento.endereco.end_rua},${evento.endereco.end_complemento},${evento.endereco.end_bairro},${evento.endereco.end_cidade},${evento.endereco.end_estado}`)"
        >
         Ver no mapa
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from '@/helpers/api';
import moment from 'moment';
import AppBar from "./AppBar.vue";
import { useGoTo } from 'vuetify'
export default {
  components: {
    AppBar
  },
  setup () {
    const goTo = useGoTo()
    return { goTo }
  },
  data() {
    return {
      search: '',
      evento: {
        datas: [],
        endereco: []
      },
    }
  },
  created () {
    this.getEventos();
  },
  methods: {
    loc () {
      this.goTo(this.$refs.location, {
        duration: 700,
        easing: 'linear'
      });
    },
    formataData(datas) {
      let days = '';
      datas.map(data => {
          days += '<p>'+moment(data).format('DD [de] MMMM [às] LT')+'</p>'
      });

      return days;
    },
    getEventos() {
      this.loadingListagem = true;
      Api.post("compras/buscar/" + this.$route.params.eve_uuid)
        .then((res) => {
          this.evento = res.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        });
    },
  }
}
</script>