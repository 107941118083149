<template>
  <v-container fluid>
    <v-row no-gutters>
      <span class="text-h4">Clientes</span>
      <v-spacer></v-spacer>
      <v-btn rounded="xl" class="blue-background text-white" @click="actions()">Novo Cliente</v-btn>
    </v-row>
    <v-row class="mt-1">
      <v-col>
        <clientes-listagem @actions="actions" :key="keyListagem"></clientes-listagem>
      </v-col>
    </v-row>
  </v-container>
  <v-row justify="center">
    <v-dialog v-model="dialogCadastro" persistent width="768">
      <clientes-cadastro :action="actionCadastro" @fechaDialog="fecharDialog" :cli_uuid="cli_uuid" :key="dialogCadastro"></clientes-cadastro>
    </v-dialog>
  </v-row>
  <delete-card :titleCard="cli_nome" :abrirDialog="dialogDelete" :key="keyDelete" @fecharDialogDelete="fecharDialog" @confirmDelete="deleteCliente"></delete-card>
</template>

<script>
import ClientesListagem from '@/components/clientes/ClientesListagem.vue';
import ClientesCadastro from '@/components/clientes/ClientesCadastro.vue';
import DeleteCard from '@/components/DeleteCard.vue';
import Api from '@/helpers/api';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  components: {
    ClientesListagem,
    ClientesCadastro,
    DeleteCard
  },
  data() {
    return {
      dialogCadastro: false,
      dialogDelete: false,
      cli_uuid: null,
      cli_nome: null,
      actionCadastro: null,
      keyListagem: 0,
      keyDelete: 0
    }
  },
  methods: {
    deleteCliente () {
      if (!this.cli_uuid) return
      Api.post('clientes/excluir', { cli_uuid: this.cli_uuid })
        .then(res => {
          toast.success(res.data.message)
        })
        .catch(err => {
          toast.error(err.response.data.message)
        })
        .finally(() => {
          this.fecharDialog();
        })
    },
    fecharDialog () {
      this.keyListagem++;
      this.cli_uuid = null;
      this.cli_nome = null;
      this.dialogDelete = false;
      this.dialogCadastro = false;
    },
    actions (action='create', uuid=null, nome=null) {
      this.actionCadastro = action;
      this.cli_uuid = uuid;
      this.cli_nome = nome;
      if (action == 'delete') {
        this.dialogDelete = true;
        this.keyDelete++
        return
      } 
      this.dialogCadastro = true;
    }
  }
}
</script>
