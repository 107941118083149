import locations from '../assets/estados-cidades.json';

class map {
    static getEstados() {
        return locations.estados.map(estado => estado.nome);
    }
    
    static getEstadoNomeByUf(uf) {
        let estado = locations.estados.find(estado => estado.sigla == uf);
        return estado ? estado.nome : null;
    }

    static getCidadesByEstadoNome(nome) {
        let estado = locations.estados.find(estado => estado.nome == nome);
        return estado.cidades;
    }
}

export default map;