<template>
  <v-row no-gutters>
    <v-card
      cols="12"
      sm="12"
			v-show="eventos.length"
			:title="item.eve_titulo"
      variant="outlined"
      rounded="lg"
      min-width="19%"
			class="mr-3 mt-3"
      v-for="(item, index) in eventos"
      :key="index"
		>
      <v-card-text class="pb-0">
        <p>Local: {{ item.eve_local }}</p>
        <p>Data: {{ formatter.formatDate(item.eve_data, "LLL") }}</p>
			</v-card-text>
			<v-card-actions>
        <span class="ml-2" v-if="item.eve_ativo">
          <v-icon color="green">mdi-check</v-icon>
          Ativo
        </span>
        <span class="ml-2" v-else>
          <v-icon color="red">mdi-close</v-icon>
          Inativo
        </span>
				<v-spacer></v-spacer>
        
        <v-tooltip text="Participantes" location="top">
					<template v-slot:activator="{ props }">
						<v-btn flat density="comfortable" @click="openParticipantes(item)" v-bind="props">
							<v-icon size="x-large" icon="mdi-account"></v-icon>
						</v-btn>
					</template>
				</v-tooltip>
        <v-tooltip text="Copiar link de venda" location="top">
					<template v-slot:activator="{ props }">
						<v-btn icon flat density="comfortable" @click="$emit('actions', 'copy', item.eve_uuid)" v-bind="props">
							<v-icon icon="mdi-eye"></v-icon>
						</v-btn>
					</template>
				</v-tooltip>
				<v-tooltip text="Editar" location="top">
					<template v-slot:activator="{ props }">
						<v-btn icon flat density="comfortable" @click="$emit('actions', 'edit', item.eve_uuid)" v-bind="props">
							<v-icon icon="mdi-pencil"></v-icon>
						</v-btn>
					</template>
				</v-tooltip>

				<!--
        <v-tooltip text="Excluir" location="top">
					<template v-slot:activator="{ props }">
						<v-btn icon flat density="comfortable" @click="$emit('actions', 'delete', item.eve_uuid)" v-bind="props">
							<v-icon icon="mdi-delete"></v-icon>
						</v-btn>
					</template>
				</v-tooltip>
        -->
			</v-card-actions>
    </v-card>
		<v-card
			v-if="!eventos.length"
      variant="outlined"
      rounded="lg"
			class="mt-2"
      width="300"
			height="130"
			:loading="loadingListagem"
		>
			<v-card-title v-if="!loadingListagem">Cadatre um evento</v-card-title>
      <v-card-text  v-if="!loadingListagem">
				<p>Nenhum evento foi encontrado.</p>
			</v-card-text>
			<template v-slot:loader="{ isActive }">
				<v-row justify="center" class="mt-11" v-if="isActive">
					<v-progress-circular indeterminate></v-progress-circular>
				</v-row>
      </template>
    </v-card>
  </v-row>
  <v-dialog v-model="dialogParticipantes" width="1100">
    <eventos-participantes @close="closeParticipantes()" :eve_uuid="evento.eve_uuid" :eve_titulo="evento.eve_titulo"></eventos-participantes>
  </v-dialog>
</template>

<script>
import Api from "@/helpers/api.js";
import Formatters from "@/helpers/formatters.js";
import EventosParticipantes from "./EventosParticipantes.vue"
export default {
  components: {
    EventosParticipantes
  },
  emits: ['close', 'actions'],
  data() {
    return {
			formatter: Formatters,
      evento: null,
      eventos: [],
      dialogParticipantes: false,
      loadingListagem: false,
    };
  },
  mounted() {
    this.getEventos();
  },
  methods: {
    closeParticipantes() {
      this.evento = []
      this.dialogParticipantes = false;
    },
    openParticipantes(evento) {
      this.evento = evento;
      this.dialogParticipantes = true
    },
    getEventos() {
      this.loadingListagem = true;
      Api.get("eventos")
        .then((res) => {
          this.eventos = res.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        });
    },
  },
};
</script>
