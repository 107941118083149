<template>
  <v-container fluid>
    <v-row no-gutters>
      <span class="text-h4">Carteiras</span>
      <v-spacer></v-spacer>
      <v-btn rounded="xl" class="blue-background text-white" @click="actions()">Nova Carteira</v-btn>
    </v-row>
    <v-row class="mt-1">
      <v-col>
        <carteiras-listagem @actions="actions" :key="keyListagem"></carteiras-listagem>
      </v-col>
    </v-row>
  </v-container>
  <v-row justify="center">
    <v-dialog v-model="dialogCadastro" persistent width="468">
      <carteiras-cadastro :action="actionCadastro" @fechaDialog="fecharDialog" :car_uuid="car_uuid" :key="dialogCadastro"></carteiras-cadastro>
    </v-dialog>
  </v-row>
  <delete-card :titleCard="car_nome" :abrirDialog="dialogDelete" :key="keyDelete" @fecharDialogDelete="fecharDialog" @confirmDelete="deleteCarteira"></delete-card>
</template>

<script>
import CarteirasListagem from '@/components/carteiras/CarteirasListagem.vue';
import CarteirasCadastro from '@/components/carteiras/CarteirasCadastro.vue';
import DeleteCard from '@/components/DeleteCard.vue';
import Api from '@/helpers/api';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  components: {
    CarteirasListagem,
    CarteirasCadastro,
    DeleteCard
  },
  data() {
    return {
      dialogCadastro: false,
      dialogDelete: false,
      car_uuid: null,
      car_nome: null,
      actionCadastro: null,
      keyListagem: 0,
      keyDelete: 0
    }
  },
  methods: {
    deleteCarteira () {
      if (!this.car_uuid) return
      Api.post('carteiras/excluir', { car_uuid: this.car_uuid })
        .then(res => {
          toast.success(res.data.message)
        })
        .catch(err => {
          toast.error(err.response.data.message)
        })
        .finally(() => {
          this.fecharDialog();
        })
    },
    fecharDialog () {
      this.keyListagem++;
      this.car_uuid = null;
      this.car_nome = null;
      this.dialogDelete = false;
      this.dialogCadastro = false;
    },
    actions (action='create', uuid=null, nome=null) {
      this.actionCadastro = action;
      this.car_uuid = uuid;
      this.car_nome = nome;
      if (action == 'delete') {
        this.dialogDelete = true;
        this.keyDelete++
        return
      } 
      this.dialogCadastro = true;
    }
  }
}
</script>
