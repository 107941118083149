<template>
  <v-form validate-on="blur"  ref="formLogin" @submit.prevent="login" style="height: 100vh;">
    <div class="gradient d-flex flex-column align-center justify-space-around" position="absolute">
      <div>
        <img :src="require('@/assets/ndpz_logo.svg')" height="80" />
        <p class="blue-color text-center">Crescer e transformar</p>
      </div>
      <v-container class="d-flex flex-column align-center justify-space-around">
        <v-card
          rounded="xl"
          class="yellow-background mb-7"
          max-width="1000"
          width="80%"
          min-height="300"
        >
          <v-row
            no-gutters
            align="center"
            justify="space-between"
            class="pa-12"
            style="height: 100%"
          >
            <v-col md="5" xl="5" cols="12" sm="12" v-if="!criarConta">
              <div class="text-h6 text-center">
                <p>
                  Bem-vindo(a) aos eventos da <span class="blue-color">NDPZ</span>!
                </p>
                Faça o login para começar.
              </div>
            </v-col>
            <v-col md="5" xl="5" cols="12" sm="12" v-else>
              <v-text-field
                class="my-1"
                single-line
                density="compact"
                bg-color="white"
                variant="outlined"
                prepend-inner-icon="mdi-account"
                label="Nome"
                :rules="rules.getMandatoryRule()"
                v-model="user.usu_nome"
              ></v-text-field>
              <v-text-field
                class="my-1"
                single-line
                density="compact"
                bg-color="white"
                variant="outlined"
                prepend-inner-icon="mdi-email"
                label="Email"
                type="email"
                :rules="rules.getEmailRule()"
                v-model="user.usu_email"
              ></v-text-field>
              <v-text-field
                single-line
                density="compact"
                bg-color="white"
                variant="outlined"
                prepend-inner-icon="mdi-lock"
                :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="showPassword = !showPassword"
                v-model="user.usu_senha"
                label="Senha"
                :type="!showPassword ? 'password' : 'text'"
                :rules="rules.getTextLengthRule(6)"
              ></v-text-field>
            </v-col>
            <v-col md="5" xl="5" cols="12" sm="12" v-if="!criarConta">
              <v-text-field
                class="mb-1"
                single-line
                density="compact"
                bg-color="white"
                variant="outlined"
                prepend-inner-icon="mdi-email"
                label="Email"
                type="email"
                :rules="rules.getEmailRule()"
                v-model="user.usu_email"
              ></v-text-field>
              <v-text-field
                single-line
                density="compact"
                bg-color="white"
                variant="outlined"
                prepend-inner-icon="mdi-lock"
                :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="showPassword = !showPassword"
                v-model="user.usu_senha"
                label="Senha"
                :type="!showPassword ? 'password' : 'text'"
                :rules="rules.getTextLengthRule(6)"
              ></v-text-field>
              <v-btn type="submit" block class="mt-2 blue-background text-white" :loading="loadingSubmit">
                Entrar
              </v-btn>
              <v-btn size="small" flat class="mt-2" variant="outlined" density="compact" @click="criarConta = !criarConta">Criar conta</v-btn>
            </v-col>
            <v-col md="5" xl="5" cols="12" sm="12" v-else>
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                type="text"
                label="CPF"
                v-model="user.usu_cpf"
                :loading="loadingSubmit"
                :rules="rules.getCpfRule()"
                v-mask-cpf
              ></v-text-field>
              <v-text-field
                :rules="rules.getPhoneRule()"
                density="compact"
                bg-color="white"
                variant="outlined"
                type="text"
                label="Telefone"
                prepend-inner-icon="mdi-phone-outline"
                :loading="loadingSubmit"
                v-model="user.usu_telefone"
                v-mask-phone.br
              ></v-text-field>
              <v-btn block class="mt-2 blue-background text-white" :loading="loadingSubmit" @click="singUp()">
                Criar conta
              </v-btn>
              <v-btn size="small" flat class="mt-2" variant="outlined" density="compact" @click="setCriarConta()">Fazer login</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </div>
  </v-form>
</template>

<script>
import Rules from "@/helpers/rules.js";
import Api from "@/helpers/api.js";
import { toast } from 'vue3-toastify';
import Login from '@/helpers/login';
  export default {
    data() {
      return {
        rules: Rules,
        showPassword: false,
        formLogin: false,
        loadingSubmit: false,
        criarConta: false,
        user: {
          usu_email: "",
          usu_senha: "",
        },
      };
    },
    methods: {
      setCriarConta() {
        this.criarConta = !this.criarConta;
        this.user = {
          usu_nome: "",
          usu_email: "",
          usu_senha: "",
          usu_cpf: null,
          usu_telefone: null
        }
      },
      singUp () {
        this.loadingSubmit = true;
        this.$refs.formLogin.validate();
        if (!this.$refs.formLogin.isValid) return this.loadingSubmit = false;
        Api.post("usuarios/novo", this.user)
          .then((response) => {
            Login.saveToken(response.data.authorisation.token, 'user');
            this.$router.go('EventosCompras');
          })
          .catch(err => {
            toast.error(err.response.data.message)
          })
          .finally(()=> {
            this.loadingSubmit = false;
          });
      },
      async login(event) {
        this.loadingSubmit = true;
        const results = await event;
        if (!results.valid) return this.loadingSubmit = false;
        await Api.post("usuarios/login", this.user)
          .then((response) => {
            Login.saveToken(response.data.authorisation.token, 'user');
            this.$router.go('Lancamentos');
          })
          .catch(err => {
            toast.error(err.response.data.message)
          })
          .finally(()=> {
            this.loadingSubmit = false;
          });
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  @import "@/assets/scss/_variables.sass";
  
  .gradient {
    height: 100%;
    background: linear-gradient(0deg, $blue 30%, white 30%);
  }
  </style>
  