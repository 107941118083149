import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueMask from '@devindex/vue-mask';
import moment from 'moment';
import Vue3Toastify from 'vue3-toastify';

loadFonts()

moment.locale('pt-BR');

createApp(App)
.use(router)
.use(vuetify)
.use(VueMask)
.use(Vue3Toastify, {
    autoClose: 3000,
    position: 'bottom-right'
})
.mount('#app')
