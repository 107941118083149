<template>
  <v-container>
    <v-card variant="outlined" rounded="lg" v-if="relatorio.rel_data">
      <v-card-title class="font-weight-bold">
        <v-row no-gutters>

        <!-- Relatório - Criado em: {{ formatter.formatDate(relatorio.created_at, "LLL") }} -->
        <span class="text-capitalize">Relatório - {{ formatter.formatDate(relatorio.rel_data, "MMMM YYYY") }}</span>
        <v-spacer></v-spacer>
        {{ relatorio.rel_codigo }}
      </v-row>
      </v-card-title>
      <v-row align="center" no-gutters justify="end">
        <v-col md="4">
          <v-select
            :items="[{title:'Todos', value: ''}, {title:'Receita', value: 'Receita'}, {title:'Despesa', value: 'Despesa'}]"
            class="mr-5"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            hide-details
            density="compact"
          ></v-select>
        </v-col>
        <!-- <v-col md="4">
          <v-text-field
            class="mr-5"
            v-model="search"
            label="Buscar"
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            hide-details
            density="compact"
          ></v-text-field>
        </v-col> -->
      </v-row>
      <v-card-text>
        <v-row no-gutters>
          <v-data-table
            :headers="headers"
            :items="lancamentos"
            :items-per-page="-1"
            :search="search"
          
            height="70vh"
            width="300"
          >
            <template v-slot:item.lan_data="{ value }">
              {{ formatter.formatDate(value) }}
            </template>
            <template v-slot:item.lan_tipo="{ value }">
              <v-chip size="small" v-if="value == 'receita'" class="text-capitalize" prepend-icon="mdi-plus" color="green">{{value}}</v-chip>
              <v-chip size="small" v-else-if="value == 'despesa'" class="text-capitalize" prepend-icon="mdi-minus" color="red">{{value}}</v-chip>
            </template>
            <template v-slot:item.lan_valor_bruto="{ value }">
              {{ formatter.floatToMoney(value) || "-" }}
            </template>
            <template v-slot:item.totalDespesa="{ value }">
              {{ value > 0 ? formatter.floatToMoney(value) : "-" }}
            </template>
            <template v-slot:item.arquivos="{ value }">
                <v-menu
                  transition="slide-y-transition"
                  v-if="value.length"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      flat
                      density="compact"
                    >
                      Links
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, i) in value"
                      :key="i"
                    >
                      <v-list-item-title>
                        <a :href="item.laa_url" target="_blank" class="text-decoration-none text-black">
                          {{ item.laa_nome.slice(item.laa_nome.indexOf('-')+1, item.laa_nome.length) }}
                        </a>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                
            </template>
            <template v-slot:bottom>
              <v-divider class="mb-5"></v-divider>
              <v-row no-gutters class="text-subtitle-1">
                <v-spacer></v-spacer>
                <v-col>
                  <div>
                    <span>{{ lancamentos.length }}</span> /
                    <span class="text-green">{{ lancamentos.filter(lan => lan.lan_tipo == 'receita').length }}</span> /
                    <span class="text-red">{{ lancamentos.filter(lan => lan.lan_tipo == 'despesa').length  }}</span>
                    
                  </div>
                </v-col>
                <v-col>
                  <div>
                    Receita:
                    <span class="text-green">{{ formatter.floatToMoney(totais.receita) }}</span>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    Despesa:
                    <span class="text-red">{{ formatter.floatToMoney(totais.despesa) }}</span>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    Total:
                    <span :class="totais.receita >= totais.despesa ? 'text-green' : 'text-red'">{{ formatter.floatToMoney(totais.total) }}</span>
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>

  <v-row no-gutters>
    <v-dialog v-model="dialogOTP" max-width="400" persistent>
      <v-card>
        <v-form ref="formRelatorio" @submit.prevent="getRelatorio">
          <v-card-title class="yellow-background">
            <v-row no-gutters align="center">
              <span class="text-h5">Insira o código do relatório</span>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-otp-input ref="inputOTP" autofocus type="text" :length="8" v-model="codigo" :loading="loadingOTP"></v-otp-input>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded="xl" class="blue-background text-white px-4" type="submit" :loading="loadingOTP">Ver relatório</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Api from "@/helpers/api.js";
import Formatters from "@/helpers/formatters.js";
import { toast } from 'vue3-toastify';

export default {
  data() {
    return {
      search: '',
      headers: [
        { title: 'Descrição', value: 'lan_descricao' },
        { title: 'Data', value: 'lan_data' },
        { title: 'Tipo', value: 'lan_tipo' },
        { title: 'Despesas', value: 'totalDespesa' },
        { title: 'Arquivos', value: 'arquivos' },
        { title: 'Valor', value: 'lan_valor_bruto' },
      ],
      formatter: Formatters,
      dialogOTP: true,
      lancamentos: [],
      totais: [],
      relatorio: [],
      codigo: '',
      loadingOTP: false,
    };
  },
  mounted() {
    this.codigo = this.$route.query.codigo || '';
    if (this.codigo) this.getRelatorio();
  },
  methods: {
    getRelatorio() {
      if (this.codigo.length < 8) {
        this.disableFields = false;
        return
      }
      this.loadingOTP = true;
      
      Api.get("relatorios/buscar?codigo=" + this.codigo.toUpperCase())
        .then((res) => {
          this.$router.replace({ name: `Relatorio`, query: { codigo: this.codigo.toUpperCase() }});
          this.dialogOTP = false;
          this.lancamentos = res.data.message.lancamentos;
          this.relatorio = res.data.message.relatorio;
          this.totais = res.data.message.totais;
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.response.data.message, {
            position: `bottom-center`
          });
          this.$refs.inputOTP.focus();
        })
        .finally(() => {
          this.loadingOTP = false;
        });
    },
  },
};
</script>
