<template>
  <v-row class="mt-1">
    <v-col v-for="(i, index) in categorias" :key="index">
      <v-card variant="outlined" rounded="lg" color="grey">
        <v-card-title class="text-grey-darken-3 text-capitalize">{{ index }}</v-card-title>
        <v-card-text>
          <v-table>
            <thead>
              <tr>
                <th class="text-left">Nome</th>
                <th class="text-center">Em uso</th>
                <th class="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in i" :key="item.cat_nome">
                <td>
                  <v-btn icon :color="item.cat_cor" disabled size="15" class="mr-1"></v-btn>
                  {{ item.cat_nome }}
                </td>
                <td class="text-center">{{ item.cat_uso }}</td>
                <td class="text-right pr-0">
                  <!-- Edit -->
									<v-tooltip text="Editar" location="top">
										<template v-slot:activator="{ props }">
											<v-btn icon flat density="comfortable" @click="$emit('actions', 'edit', item.cat_uuid, item.cat_nome)">
												<v-icon v-bind="props" icon="mdi-pencil"></v-icon>
											</v-btn>
										</template>
									</v-tooltip>
									<!-- End Edit -->
                  <!-- Delete -->
									<v-tooltip text="Excluir" location="top">
										<template v-slot:activator="{ props }">
											<v-btn icon flat density="comfortable" @click="$emit('actions', 'delete', item.cat_uuid, item.cat_nome)">
												<v-icon v-bind="props" icon="mdi-delete"></v-icon>
											</v-btn>
										</template>
									</v-tooltip>
									<!-- End Delete -->
                </td>
              </tr>
            </tbody>
          </v-table>
          <v-row v-show="!i.length && !loadingListagem" no-gutters justify="center">
            <span class="mt-2 text-grey-darken-2">Nenhuma categoria encontrada!</span>
          </v-row>
          <v-row v-show="!i.length && loadingListagem" no-gutters justify="center">
            <v-progress-circular indeterminate class="mt-3" width="5" size="37" color="primary"></v-progress-circular>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Api from '@/helpers/api';

export default {
  data() {
    return {
      categorias: {"receita":[],"despesa":[],"transferencia":[]},
      loadingListagem: false
    }
  },
  mounted () {
    this.getCategorias();
  },
  methods: {
    getCategorias () {
      this.loadingListagem = true;
      Api.get('categorias')
        .then(res => {
          this.categorias = res.data;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        })
    }
  }
};
</script>

