<template>
  <v-card variant="outlined" rounded="lg" color="grey">
    <v-card-text>
      <v-table>
        <thead>
          <tr>
            <th class="text-left">Nome</th>
            <th class="text-left">CPF/CNPJ</th>
            <th class="text-left">Email</th>
            <th class="text-left">Telefone</th>
            <th class="text-left">Segmento</th>
            <th class="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in clientes" :key="item" v-show="clientes.length">
            <td>{{ item.cli_nome }}</td>
            <td>{{ item.cli_cpf_cnpj || '-' }}</td>
            <td class="text-left">
              <v-icon class="mr-1" v-if="item.cli_email">mdi-email</v-icon>
              <span>{{ item.cli_email || '-' }}</span>
            </td>
            <td class="text-left">
              <v-icon class="mr-1" v-if="item.cli_telefone">mdi-phone</v-icon>
              <span>{{ item.cli_telefone || '-' }}</span>
            </td>
            <td class="text-left">{{ item.cli_segmento || '-' }}</td>
            <td class="text-right pr-0">
              <!-- Read -->
              <v-tooltip text="Ver cadastro completo" location="top">
                <template v-slot:activator="{ props }">
                  <v-btn icon flat density="comfortable" @click="$emit('actions', 'read', item.cli_uuid, item.cli_nome)">
                    <v-icon v-bind="props" icon="mdi-eye"></v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
              <!-- End Read -->

              <!-- Edit -->
              <v-tooltip text="Editar" location="top">
                <template v-slot:activator="{ props }">
                  <v-btn icon flat density="comfortable" @click="$emit('actions', 'edit', item.cli_uuid, item.cli_nome)">
                    <v-icon v-bind="props" icon="mdi-pencil"></v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
              <!-- End Edit -->
              
              <!-- Delete -->
              <v-tooltip text="Excluir" location="top">
                <template v-slot:activator="{ props }">
                  <v-btn icon flat density="comfortable" @click="$emit('actions', 'delete', item.cli_uuid, item.cli_nome)">
                    <v-icon v-bind="props" icon="mdi-delete"></v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
              <!-- End Delete -->
            </td>
          </tr>
        </tbody>
      </v-table>
      <v-row v-show="!clientes.length && !loadingListagem" no-gutters justify="center">
        <span class="mt-2 text-grey-darken-2">Nenhum cliente encontrado!</span>
      </v-row>
      <v-row v-show="!clientes.length && loadingListagem" no-gutters justify="center">
        <v-progress-circular indeterminate class="mt-3" width="5" size="37" color="primary"></v-progress-circular>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Api from '@/helpers/api.js';
export default {
  data() {
    return {
      clientes: [],
      loadingListagem: true
    }
  },
  mounted () {
    this.getClientes();
  },
  methods: {
    getClientes () {
      this.loadingListagem = true;
      Api.get('clientes')
        .then(res => {
          this.clientes = res.data;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        })
    }
  }
};
</script>
