<template>
  <app-bar></app-bar>
  <v-container>
    <v-row dense>
      <v-card elevation="0">
        <v-data-iterator
          :items="eventos"
          :items-per-page="6"
          :search="search"
        >
          <template v-slot:header>
            <v-toolbar class="px-2" style="background-color: white;">
              <v-text-field
                v-model="search"
                clearable
                density="comfortable"
                hide-details
                placeholder="Buscar evento"
                prepend-inner-icon="mdi-magnify"
                style="width: 300px;"
                variant="solo"
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:default="{ items }">
            <v-container class="pa-2" fluid>
              <v-row dense>
                <v-col
                  v-for="(item, index) in items"
                  :key="index"
                  cols="auto"
                  md="6"
                  sm="6"
                >
                  <v-card class="pb-3" border flat>
                    <v-img max-height="300" :lazy-src="require('@/assets/ndpz_logo.svg')" :src="`https://ndpz-app-public.s3.us-west-1.amazonaws.com/${item.raw.eve_imagem}`">
                      <template v-slot:error>
                        <v-img
                          max-height="300"
                          :src="require('@/assets/ndpz_logo.svg')"
                        ></v-img>
                      </template>
                    </v-img>

                    <v-list-item class="mb-2" :subtitle="item.raw.eve_local" width="9999">
                      <template v-slot:title>
                        <strong class="text-h6 mb-2">{{ item.raw.eve_titulo }}</strong>
                      </template>
                    </v-list-item>

                    <div class="d-flex justify-space-between px-4">
                      <div class="d-flex align-center text-caption text-medium-emphasis me-1">
                        <v-icon icon="mdi-clock" start></v-icon>
                        <div class="" v-text="formataData(item.raw.datas)"></div>
                      </div>
                    </div>
                    <div class="d-flex justify-space-between px-4 mt-4">
                      <v-spacer></v-spacer>
                      <v-btn
                        border
                        flat
                        size="small"
                        class="text-none"
                        text="Mais detalhes"
                        :to="`/eventos/compras/detalhes/${item.raw.eve_uuid}`"
                      >
                      </v-btn>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:footer="{ page, pageCount, prevPage, nextPage }">
            <div class="d-flex align-center justify-center pa-4" v-if="pageCount > 1">
              <v-btn
                :disabled="page === 1"
                icon="mdi-arrow-left"
                density="comfortable"
                variant="tonal"
                rounded
                @click="prevPage"
              ></v-btn>

              <div class="mx-2 text-caption">
                Página {{ page }} de {{ pageCount }}
              </div>

              <v-btn
                :disabled="page >= pageCount"
                icon="mdi-arrow-right"
                density="comfortable"
                variant="tonal"
                rounded
                @click="nextPage"
              ></v-btn>
            </div>
          </template>
        </v-data-iterator>
      </v-card>
    </v-row>
    <v-container class="pa-2" fluid v-if="!eventos.length">
      <v-row dense>
        <v-col
          cols="auto"
          md="6"
          sm="6"
        >
          <v-card class="pb-3" border flat min-height="150">
            <v-img max-height="150" class="my-4" :src="require('@/assets/ndpz_logo.svg')"></v-img>
            <v-list-item class="mb-2" subtitle="Aguarde os eventos ficarem disponíveis" width="9999">
              <template v-slot:title>
                <strong class="text-h6 mb-2">Nenhum evento encontrado!</strong>
              </template>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Api from '@/helpers/api';
import Formatters from '@/helpers/formatters';
import moment from 'moment';
import AppBar from "./AppBar.vue";
export default {
  components: {
    AppBar
  },
  data() {
    return {
      search: '',
      eventos: [],
    }
  },
  mounted () {
    this.getEventos();
  },
  methods: {
    formataData(datas) {
      if (datas.length == 1) return Formatters.formatDate(datas[0], 'LLL');

      let days = '';
      datas.map((data, index) => {
        if (moment(data).isSame(datas[index+1], 'month') && datas[index+1] != undefined) {
          days += moment(data).format('DD') + ', '
        } else {
          days += moment(data).format('DD [de] MMMM[ / ]')
        }
      });

      return days;
    },
    getEventos() {
      this.loadingListagem = true;
      Api.get("compras")
        .then((res) => {
          this.eventos = res.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        });
    },
  }
}
</script>