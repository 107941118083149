import { createRouter, createWebHistory } from 'vue-router'

import LoginHelper from '../helpers/login.js'

import LoginView from '../views/LoginView.vue'
import CategoriasView from '../views/CategoriasView.vue'
import ClientesView from '../views/ClientesView.vue'
import CarteirasView from '../views/CarteirasView.vue'
import LancamentosView from '../views/LancamentosView.vue'
import EventosInternoView from '../views/EventosInternoView.vue'
import RelatoriosView from '../views/RelatoriosView.vue'
import NotFoundView from '../views/NotFoundView.vue'

import EventosLogin from '@/components/eventos/login/EventosLogin.vue';
import EventosVendas from '@/components/eventos/EventosVendas.vue';
import EventosCadastro from '@/components/eventos/EventosCadastro.vue';
import EventosIngressos from '@/components/eventos/EventosIngressos.vue';
import EventosCompras from '@/components/eventos/EventosCompras.vue';
import EventosComprasDetalhes from '@/components/eventos/EventosComprasDetalhes.vue';
import EventosComprasIngressos from '@/components/eventos/EventosComprasIngressos.vue';
import EventosMeusEventos from '@/components/eventos/EventosMeusEventos.vue';
import EventosCheckin from '@/components/eventos/EventosCheckin.vue';
import Relatorio from '@/components/lancamentos/relatorios/RelatoriosRelatorio.vue';

const routes = [
  {
    path: '/:pathMatch(.*)*', // regex para qualquer rota que não exista
    name: 'NotFound',
    component: NotFoundView,
    meta: { 
      title: 'Página não encontrada',
      requiresAuth: false
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: { 
      title: 'Login',
      requiresAuth: false
    },
  },
  {
    path: '/categorias',
    name: 'Categorias',
    component: CategoriasView,
    meta: { 
      title: 'Categorias',
      requiresAuth: true
    },
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: ClientesView,
    meta: { 
      title: 'Clientes',
      requiresAuth: true
    },
  },
  {
    path: '/carteiras',
    name: 'Carteiras',
    component: CarteirasView,
    meta: { 
      title: 'Carteiras',
      requiresAuth: true
    },
  },
  {
    path: '/lancamentos',
    name: 'Lancamentos',
    component: LancamentosView,
    meta: { 
      title: 'Lançamentos',
      requiresAuth: true
    },
  },
  {
    path: '/lancamentos/relatorios',
    name: 'Relatorios',
    component: RelatoriosView,
    meta: { 
      title: 'Relatórios',
      requiresAuth: true
    },
  },
  {
    path: '/relatorio',
    name: 'Relatorio',
    component: Relatorio,
    meta: { 
      title: 'Relatório',
      requiresAuth: false
    },
  },
  {
    path: '/eventos',
    name: 'Eventos',
    component: EventosInternoView,
    meta: { 
      title: 'Eventos',
      requiresAuth: true
    },
  },
  {
    path: '/eventos/cadastro/:eve_uuid?',
    name: 'EventosCadastro',
    component: EventosCadastro,
    meta: { 
      title: 'Eventos',
      requiresAuth: true
    },
  },
  {
    path: '/eventos/ingressos/:eve_uuid?',
    name: 'EventosIngressos',
    component: EventosIngressos,
    meta: { 
      title: 'Ingressos',
      requiresAuth: true
    },
  },
  {
    path: '/eventos/compras',
    name: 'EventosCompras',
    component: EventosCompras,
    meta: { 
      title: 'Eventos',
      requiresAuth: false
    },
  },
  {
    path: '/eventos/compras/detalhes/:eve_uuid?',
    name: 'EventosComprasDetalhes',
    component: EventosComprasDetalhes,
    meta: { 
      title: 'Evento',
      requiresAuth: false
    },
  },
  {
    path: '/eventos/compras/ingressos/:eve_uuid?',
    name: 'EventosComprasIngressos',
    component: EventosComprasIngressos,
    meta: { 
      title: 'Ingressos',
      requiresAuth: false
    },
  },
  {
    path: '/eventos/login',
    name: 'EventosLogin',
    component: EventosLogin,
    meta: { 
      title: 'Login',
      requiresAuth: false
    },
  },
  {
    path: '/eventos/meusEventos',
    name: 'EventosMeusEventos',
    component: EventosMeusEventos,
    meta: { 
      title: 'Meus Eventos',
      requiresAuth: false
    },
  },
  {
    path: '/eventos/vendas/:eve_uuid?',
    name: 'EventosVendas',
    component: EventosVendas,
    meta: { 
      title: 'Vendas',
      requiresAuth: true
    },
  },
  {
    path: '/eventos/checkin',
    name: 'EventosCheckin',
    component: EventosCheckin,
    meta: { 
      title: 'Check-in',
      requiresAuth: false
    },
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to) => {
  document.title = to.meta.title + ' - NDPZ';

  if (to.path == '/') return { name: 'Login'};

  const loggedIn = LoginHelper.isLoggedIn();
  const userTypeUser = LoginHelper.verifyUserType('user');
  const userTypeAdmin = LoginHelper.verifyUserType('admin');

  if (to.meta.requiresAuth) {
    if (!loggedIn) return { name: 'Login' };
    if (userTypeUser) return { name: 'EventosCompras'};
  } else {
    if (userTypeUser && to.name == 'EventosLogin') return { name: 'EventosCompras' };
  }

  if (to.name == 'Login' && userTypeAdmin) return { name: 'Lancamentos' };
})

export default router
