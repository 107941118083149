<template>
  <v-container>
    <v-row no-gutters>
      <span class="text-h4">{{$route.params.eve_uuid ? 'Editar' : 'Novo' }} evento</span>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="10">
        <v-form ref="formEvento" @submit.prevent="saveEvento">
          <v-row no-gutters>
            <v-switch
              v-model="evento.eve_ativo"
              hide-details
              color="primary"
              :true-value="1"
              :false-value="0"
              label="Ativo"
            ></v-switch>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4" md="4">
              <v-select
                :disabled="!!evento.eve_gratuito"
                density="compact"
                variant="outlined"
                :items="itemsCarteira"
                v-model="evento.car_uuid"
                label="Carteira*"
                :loading="loadingCarteira"
                item-title="car_nome"
                item-value="car_uuid"
                :rules="evento.eve_gratuito ? [] : rules.getSelectMandatoryRule()"
              ></v-select>
            </v-col>  
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="8" md="8">
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                type="text"
                :rules="rules.getMandatoryRule()"
                label="Título*"
                v-model="evento.eve_titulo"
                :loading="loadingCadastro"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-file-input
                accept="image/*"
                density="compact"
                variant="outlined"
                label="Imagem"
                v-model="evento.eve_imagem"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <VueDatePicker 
                :format="format"
                partial-flow 
                :flow="['calendar', 'time']"
                v-model="eve_data"
                :teleport="true"
                placeholder="Selecione a data"
                select-text="Selecionar"
                cancel-text="Cancelar"
                minutes-increment="15"
                :clearable="false"
                :action-row="{ showPreview: false }"
                @update:model-value="push_date"
              >
                <!--
                  :format-locale="ptBR"
                -->
              </VueDatePicker>
            </v-col>
          </v-row>
          <v-row dense v-if="evento.datas.length" >
            <v-col>
              <v-chip 
                v-for="(i, index) in evento.datas" :key="index"
                class="ma-1"
              >
                {{ formatters.formatDate(i, 'LLL') }}
                <v-icon class="ml-1" @click="deleteData(index)">mdi-close-circle</v-icon>
              </v-chip>
            </v-col>
          </v-row>
          <v-row dense class="mt-6">
            <v-col cols="12" sm="12" md="12">
              <quill-editor
                class="mb-6"
                style="height: 260px;"
                :options="quillOptions"
                v-model:value="evento.eve_descricao"
              />
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row dense class="mt-5">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                type="text"
                :rules="rules.getMandatoryRule()"
                label="Local*"
                v-model="evento.eve_local"
                :loading="loadingCadastro"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                :loading="loadingCadastro"
                label="CEP"
                type="text"
                v-mask-cep
                prepend-inner-icon="mdi-map-marker-outline"
                v-model="evento.endereco.end_cep"
                @change="buscarCEP(evento.endereco.end_cep)"
                :hint="cepNotFound ? 'Cep não encontrado' : ''"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                density="compact"
                bg-color="white"
                variant="outlined"
                label="Estado"
                type="text"
                v-model="evento.endereco.end_estado"
                :loading="loadingCadastro"
                :items="itensEstados"
                @update:modelValue="getCidades(), evento.endereco.end_cidade = null"
                autocomplete="null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                density="compact"
                bg-color="white"
                :loading="loadingCadastro"
                variant="outlined"
                label="Cidade"
                type="text"
                v-model="evento.endereco.end_cidade"
                :items="itensCidades"
                autocomplete="null"
                :disabled="!itensCidades.length"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="8" md="8">
              <v-text-field
                :loading="loadingCadastro"
                density="compact"
                bg-color="white"
                variant="outlined"
                label="Rua"
                type="text"
                v-model="evento.endereco.end_rua"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                density="compact"
                bg-color="white"
                :loading="loadingCadastro"
                variant="outlined"
                label="Bairro"
                type="text"
                v-model="evento.endereco.end_bairro"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                :loading="loadingCadastro"
                label="Complemento"
                type="text"
                v-model="evento.endereco.end_complemento"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div class="mt-3">
            <v-btn rounded="xl" to="/eventos" class="mr-2">Voltar</v-btn>
            <v-btn rounded="xl" class="blue-background text-white px-4" type="submit" :disabled="disableFields || loadingCadastro">salvar</v-btn>
          </div>
        </v-form>
      </v-col>
      <v-col>
        <v-btn 
          rounded="xl" 
          class="blue-background text-white mb-2" 
          block 
          :disabled="!this.$route.params.eve_uuid"
          :to="`/eventos/ingressos/${this.$route.params.eve_uuid}`"
        >
          Ingressos
        </v-btn>
        <v-btn
          rounded="xl"
          class="blue-background text-white"
          block
          :disabled="!this.$route.params.eve_uuid"
          :to="`/eventos/vendas/${this.$route.params.eve_uuid}`"
        >
          Vendas
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Rules from '@/helpers/rules.js';
import Api from '@/helpers/api';
import Formatters from "@/helpers/formatters";
import Map from '@/helpers/map.brasil.js';
import axios from 'axios';

import { toast } from 'vue3-toastify';
import { quillEditor } from 'vue3-quill'
import localePtBR from 'date-fns/locale/pt-BR';
import VueDatePicker from '@vuepic/vue-datepicker';

import '@vuepic/vue-datepicker/dist/main.css';
import 'vue3-toastify/dist/index.css';

export default {
  components: {
    quillEditor,
    VueDatePicker
  },
  data() {
    return {
      itensEstados: Map.getEstados(),
      itensCidades: [],
      cepNotFound: false,
      ptBR: localePtBR,
      loadingCadastro: false,
      loadingCarteira: false,
      formatters: Formatters,
      rules: Rules,
      itemsCarteira: [],
      disableFields: false,
      cardTitle: 'Novo Evento',
      eve_data: new Date().setMinutes(0),
      imagem_evento: null,
      evento: {
        eve_ativo: 1,
        eve_titulo: '',
        datas: [],
        eve_local: '',
        eve_descricao: '',
        eve_imagem: null,
        eve_gratuito: 0,
        car_uuid: null,
        endereco: {
          end_cep: null,
          end_rua: null,
          end_bairro: null,
          end_cidade: null,
          end_estado: null,
          end_complemento: null,
        }
      },
      quillOptions: {
        placeholder: 'Descrição do evento',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }, { align: [] }],
            [],
            ['clean']
          ]
        }
      },

      // Datepicker
      format: (date) => {
        return Formatters.formatDate(date, 'DD/MM/YYYY HH:mm')
      }
    }
  },
  mounted () {
    this.getCarteira();
    this.getEvento();
  },
  methods: {
    deleteData (index) {
      this.evento.datas.splice(index, 1);
    },
    push_date(date) {
      this.evento.datas.push(date);
      this.eve_data = new Date().setMinutes(0);
    },
    getCidades () {
      this.itensCidades = Map.getCidadesByEstadoNome(this.evento.endereco.end_estado);
    },
    buscarCEP (cep) {
      axios.get(`https://viacep.com.br/ws/${cep.replace('.', '').replace('-', '')}/json/`)
        .then(response => {
          if (response.data.erro) return this.cepNotFound = true
          this.cepNotFound = false
          this.evento.endereco.end_cep = response.data.cep;
          this.evento.endereco.end_rua = response.data.logradouro;
          this.evento.endereco.end_bairro = response.data.bairro;
          this.evento.endereco.end_complemento = response.data.complemento;
          this.evento.endereco.end_estado = Map.getEstadoNomeByUf(response.data.uf);
          this.evento.endereco.end_cidade = response.data.localidade;
          this.getCidades();
        })
        .catch(error => {
          console.error(error);
        })
    },
    emitClose () {
      this.$emit('fechaDialog');
    },
    async saveEvento (event) {
      this.disableFields = true;
      const results = await event
      if (!results.valid) {
        this.disableFields = false;
        toast.info("Preencha todos os campos!", {
          position: `bottom-center`
        })
        return
      }
      if (!this.evento.datas.length) {
        this.disableFields = false;
        toast.info("Evento precisa ter pelo menos uma data!", {
          position: `bottom-center`
        })
        return
      }

      this.evento.datas.map((data, i) => {
        this.evento.datas[i] = Formatters.formatDateToEn(data, "DD/MM/YYYY HH:mm", "YYYY-MM-DD  HH:mm")
      })

      var formData = new FormData();
      for (var key in this.evento) {
        if (key == 'eve_imagem' && this.evento[key] != null) {
          formData.append('eve_imagem', this.evento[key][0]);
        } else if (Array.isArray(this.evento[key]) || key == 'endereco') {
          formData.append(key, JSON.stringify(this.evento[key]));
        } else {
          if (this.evento[key] === null) {
            this.evento[key] = "";
          }
          if (typeof this.evento[key] != 'object') {
            formData.append(key, this.evento[key]);
          }
        }
      }

      let notificacao = toast.loading("Salvando evento");

      let rota = 'novo';
      if (this.$route.params.eve_uuid) rota = 'editar'

      Api.post(`eventos/${rota}`, formData, true)
        .then(res => {
          toast.update(notificacao, {
            render: res.data.message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
          });
          setTimeout(() => {
            this.$router.push('/eventos/ingressos/' + res.data.eve_uuid);
          }, 1000);
          
        })
        .catch(err => {
          toast.update(notificacao, {
            render: err.response.data.message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
          })
        }).finally(() => {
          this.disableFields = false;
        });
    },
    getCarteira() {
      this.loadingCarteira = true;
      Api.get("carteiras/select")
        .then((res) => {
          this.itemsCarteira = res.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCarteira = false;
        });
    },
    getEvento () {
      if (!this.$route.params.eve_uuid) return
      this.loadingCadastro = true
      Api.post('eventos/buscar', { eve_uuid: this.$route.params.eve_uuid })
        .then(res => {
          this.evento = res.data
          this.imagem_evento = this.evento.eve_imagem;
          this.evento.eve_imagem = [];
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCadastro = false;
        })
    }
  }
};
</script>