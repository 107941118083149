import axios from "axios";
import Cookies from "js-cookie";

var URL = 'https://api.ndpz.app/api'
if (window.location.host == 'localhost:8080') URL = 'http://localhost:8081/api'

class Api {
  static get(path) {
    return axios.get(`${URL}/${path}`, {
      headers: this.headers()
    });
  }

  static getBlob(path) {
    return axios.get(`${URL}/${path}`, {
      headers: this.headers(),
      responseType: 'blob'
    });
  }

  static post(path, body, image=false) {
    return axios.post(`${URL}/${path}`, body, {
      headers: this.headers(image)
    });
  }

  static headers(image) {
    let headers = { "Content-Type": "application/json" }
    if (image) headers = { "Content-Type": "multipart/form-data" }
    
    let auth_token = Cookies.get('auth_token')
    if (auth_token) Object.assign(headers, { Authorization: `Bearer ${auth_token}`})

    return headers;
  }
}

export default Api;