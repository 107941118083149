<template>
  <v-dialog v-model="dialogDelete" width="700">
    <v-card>
      <v-card-title class="text-h5 red-background text-white">
        <v-row no-gutters>
          <span>Tem certeza que deseja excluir {{ titleCard }}?</span>
        </v-row>
      </v-card-title>
      <v-card-text>
        <p class="text-grey-darken-2">
          <span class="text-red font-weight-bold">ATENÇÃO:</span> Ao confirmar a exclusão deste item, ele será <span class="font-weight-bold">removido permanentemente</span> da listagem e  <span class="font-weight-bold">não poderá ser recuperado</span>. Certifique-se de que realmente deseja excluí-lo antes de prosseguir.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded="xl" class="mr-2" @click="fecharDialogDelete">Cancelar</v-btn>
        <v-btn rounded="xl" color="error" @click="confirmDelete" :disabled="disableDelete">Excluir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    abrirDialog: Boolean,
    titleCard: String
  },
  data() {
    return {
      dialogDelete: false,
      disableDelete: true
    };
  },
  mounted () {
    this.dialogDelete = this.abrirDialog;
    setTimeout(() => {
      this.disableDelete = false;
    }, 1600);    
  },
  methods: {
    fecharDialogDelete() {
      this.dialogDelete = false;
      this.$emit('fecharDialogDelete');
    },
    confirmDelete() {
      this.$emit('confirmDelete');
      this.dialogDelete = false;
    }
  }
};
</script>

<style lang="scss">
.red-background {
  background-color: #B00020 !important;
}
</style>
