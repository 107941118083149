<template>
  <v-app-bar elevation="1" color="#FFBA00" :density="this.$vuetify.display.name == 'xs' ? 'prominent' : 'default'">
    <v-app-bar-title>
      <v-row no-gutters align="center">
        <v-col>
          <img :src="require('@/assets/ndpz_logo.svg')" height="45" class="ml-5" />
        </v-col>
        <v-col cols="12" sm="8" class="text-right">
          <v-btn class="pa-2 mr-2" to="/eventos/checkin">
            Check-in
          </v-btn>
          <v-btn class="pa-2 mr-2" to="/eventos/compras">
            Eventos
          </v-btn>
          <v-btn class="pa-2 mr-2" to="/eventos/meusEventos" v-if="isLoggedIn">
            Meus eventos
          </v-btn>
          <v-btn class="pa-2 mr-2" @click="logout()" v-if="isLoggedIn">
            Sair
          </v-btn>
          <v-btn class="pa-2 mr-2" to="/eventos/login" v-else>
            Entrar
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar-title>
  </v-app-bar>
</template>

<script>
import Login from '@/helpers/login';
import Api from '@/helpers/api';
import Cookie from 'js-cookie';
export default {
  data() {
    return {
      isLoggedIn: Login.verifyUserType('user')
    }
  },
  methods: {
    logout() {
      Api.post("usuarios/logout")
        .then(response => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(()=> {
          Cookie.remove('auth_token');
          Cookie.remove('type_token');
          this.$router.go('EventosCompras');
        })
    },
  }
}
</script>
