<template>
  <div>
    <v-card>
      <v-card-title class="yellow-background">
        <v-row no-gutters align="center">
          <span class="text-h5">{{ cardTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn icon flat density="comfortable" @click="emitClose()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-form
        validate-on="blur"
        ref="formLancamento"
        @submit.prevent="saveLancamento"
        :disabled="action == 'read' || loadingCadastro"
      >
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="4" md="3">
              <v-select
                density="compact"
                variant="outlined"
                :items="itemsTipos"
                v-model="lancamento.lan_tipo"
                label="Tipo"
                @update:modelValue="getCategoria(), lancamento.cat_uuid = null, lancamento.lan_repeticao = '1'"
                :disabled="action != 'create'"
              >
              </v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-switch
                density="compact"
                label="Lançamento confirmado"
                color="primary"
                :false-value="0"
                :true-value="1"
                v-model="lancamento.lan_confirmado"
              >
              </v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                :loading="loadingCadastro"
                type="text"
                label="Descrição*"
                :rules="rules.getMandatoryRule()"
                v-model="lancamento.lan_descricao"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                type="text"
                :label="lancamento.lan_repeticao == 2 ? 'Valor por parcela*' : 'Valor*'"
                :loading="loadingCadastro"
                v-model="lan_valor"
                v-mask-decimal.br="2"
                prefix="R$"
                :rules="rules.getMonetaryRule()"
                @blur="calcularDespesa()"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                type="text"
                label="Data"
                :loading="loadingCadastro"
                v-model="lan_data"
                v-mask-date.br
                :rules="rules.getDateRule()"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                density="compact"
                variant="outlined"
                :items="itemsRepeticao"
                v-model="lancamento.lan_repeticao"
                :disabled="lancamento.lan_tipo == 'transferencia' || action != 'create'"
                label="Repetição"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row dense v-if="lancamento.lan_tipo == 'transferencia'">
            <v-divider></v-divider>
            <v-col cols="12" sm="12" md="12" class="mb-3">
              <span>Configurar transferência</span>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                density="compact"
                variant="outlined"
                :items="itemsCarteira.filter(car => car.car_uuid != lancamento.lan_transferencia_para)"
                item-title="car_nome"
                item-value="car_uuid"
                v-model="lancamento.lan_transferencia_de"
                label="De*"
                clearable
                :rules="lancamento.lan_tipo == 'transferencia' ? rules.getSelectMandatoryRule() : []"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                density="compact"
                variant="outlined"
                :items="itemsCarteira.filter(car => car.car_uuid != lancamento.lan_transferencia_de)"
                item-title="car_nome"
                item-value="car_uuid"
                v-model="lancamento.lan_transferencia_para"
                label="Para*"
                clearable
                @update:model-value="lancamento.car_uuid = lancamento.lan_transferencia_para"
                :rules="lancamento.lan_tipo == 'transferencia' ? rules.getSelectMandatoryRule() : []"
               >
              </v-select>
            </v-col>
            <v-divider class="mb-3"></v-divider>
          </v-row>
          <v-row dense v-if="lancamento.lan_repeticao == 2">
            <v-divider></v-divider>
            <v-col cols="12" sm="12" md="12" class="mb-3">
              <span>Configurar repetição</span>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                density="compact"
                variant="outlined"
                :items="itemsPeriodicidade"
                v-model="lancamento.lan_periodicidade"
                label="Periodicidade*"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                type="tel"
                label="Parcelas*"
                v-model="lancamento.lan_parcelas"
                :rules="lancamento.lan_repeticao == 2 ? rules.getMonetaryRule() : []"
                v-mask-number
              ></v-text-field>
            </v-col>
            <!--<v-col cols="12" sm="4" md="4" >
              <v-btn dense color="primary">Personalizar</v-btn>
            </v-col>-->
            <v-divider class="mb-3"></v-divider>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4" md="4">
              <v-select
                density="compact"
                variant="outlined"
                :items="itemsCategoria"
                v-model="lancamento.cat_uuid"
                label="Categoria*"
                :loading="loadingCategoria"
                item-title="cat_nome"
                item-value="cat_uuid"
                :rules="rules.getSelectMandatoryRule()"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                density="compact"
                variant="outlined"
                :items="itemsCarteira"
                v-model="lancamento.car_uuid"
                label="Carteira*"
                :loading="loadingCarteira"
                item-title="car_nome"
                item-value="car_uuid"
                :rules="rules.getSelectMandatoryRule()"
                :disabled="lancamento.lan_tipo == 'transferencia'" 
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                density="compact"
                variant="outlined"
                :items="itemsCliente"
                v-model="lancamento.cli_uuid"
                label="Cliente"
                clearable
                :loading="loadingCliente"
                item-title="cli_nome"
                item-value="cli_uuid"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-file-input
              show-size
              counter
              chips
              counter-size-string="{0} arquivos ({1} ao total)"
              density="compact"
              variant="outlined"
              multiple
              label="Arquivos"
              v-model="laa_arquivos"
              @update:model-value="adicionaArquivo()"
            ></v-file-input>
          </v-row>
          <v-row no-gutters>
            <v-chip 
              v-for="(arquivo, index) in lancamento.arquivos"
              :key="index"
              class="ma-1"
              append-icon="mdi-close-circle"
              @click="deleteArquivo(index)"
              >
                <span>{{ arquivo.name ? arquivo.name : arquivo.laa_nome.slice(arquivo.laa_nome.indexOf('-')+1, arquivo.laa_nome.length) }}</span>
            </v-chip>
          </v-row>
          <v-row dense align="center" justify="space-between" v-if="lancamento.lan_tipo == 'receita'">
            <v-col cols="12" sm="6" md="6">
              <v-btn
                @click="abrirDialogDespesas()"
                color="primary" rounded="xl"
                :disabled="loadingCadastro"
              >
                {{ action != 'read' ? 'Adicionar despesas' : 'Ver despesas' }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="text-right">
              Valor das despesas:
              <span class="font-weight-bold text-red text-body-1">
                {{ formatters.floatToMoney(totalDespesa) }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="action != 'read'">
          <v-btn rounded="xl" @click="emitClose()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            rounded="xl"
            class="blue-background text-white px-4"
            type="submit"
            :disabled="disableFields || loadingCadastro"
          >
            salvar
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn rounded="xl" @click="emitClose()">Fechar</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <lancamentos-despesas :action="action" :despesas="lancamento.despesas" :valorTotal="lan_valor" @fecharDialog="fechaDespesa" :key="keyDespesa" :abrirDialog="dialogDespesas"></lancamentos-despesas>
  </div>
</template>

<script>
import Api from "@/helpers/api";
import Rules from "@/helpers/rules.js";
import Formatters from "@/helpers/formatters";
import LancamentosDespesas from "./LancamentosDespesas.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  components: {
    LancamentosDespesas,
  },
  props: {
    lan_uuid: String,
    lan_tipo: String,
    action: String,
  },
  data() {
    return {
      rules: Rules,
      formatters: Formatters,
      picker: new Date(),
      loadingCadastro: false,
      disableFields: false,
      dialogDespesas: false,
      keyDespesa: 0,
      totalDespesa: 0,
      cardTitle: "Novo lancamento",
      itemsTipos: [
        { title: "Receita", value: "receita" },
        { title: "Despesa", value: "despesa" },
        { title: "Transferência", value: "transferencia" },
      ],
      itemsRepeticao: [
        { title: "Única", value: "1" },
        { title: "Parcelada", value: "2" },
      ],
      itemsPeriodicidade: [
        { title: "Diária", value: "1" },
        { title: "Semanal", value: "2" },
        { title: "Mensal", value: "3" },
        { title: "Anual", value: "4" },
      ],
      itemsCategoria: [],
      loadingCategoria: false,
      itemsCarteira: [],
      loadingCarteira: false,
      itemsCliente: [],
      loadingCliente: false,
      lan_valor: 0,
      lan_data: "",
      laa_arquivos: [],
      lancamento: {
        lan_descricao: "",
        lan_tipo: "receita",
        lan_data: "",
        lan_valor_bruto: 0,
        lan_confirmado: 1, //flag
        lan_repeticao: "1",
        car_uuid: null,
        cat_uuid: null,
        cli_uuid: null,

        despesas: [],

        lan_parcelas: 2,
        lan_periodicidade: "3",
        lan_transferencia_de: null,
        lan_transferencia_para: null,
        usu_uuid: null,

        arquivos: []
      },
    };
  },
  mounted() {
    this.getCategoria();
    this.getCarteira();
    this.getCliente();
    if (this.action != "create") this.getLancamento();
    if (this.action == "edit") this.cardTitle = "Editar Lancamento";
    if (this.action == "read") this.cardTitle = "Ver Lancamento";
  },
  methods: {
    emitClose() {
      this.$emit("fechaDialog");
    },
    async saveLancamento(event) {
      this.disableFields = true;
      const results = await event;
      if (!results.valid) {
        this.disableFields = false;
        toast.info("Preencha todos os campos!", {
          position: `bottom-center`
        })
        return
      }
      let notificacao = toast.loading("Salvando lançamento");
      let rota = "novo";
      if (this.action == "edit") rota = "editar";
      this.lancamento.lan_valor_bruto = Formatters.moneyToFloat(this.lan_valor);
      this.lancamento.lan_data = Formatters.formatDateToEn(this.lan_data);
      this.calcularDespesa()

      var formData = new FormData();
      for (var key in this.lancamento) {
        if (key == 'arquivos') {
          this.lancamento.arquivos.map(arq => {
            if (!arq.laa_nome) {
              formData.append('arquivos[]', arq);
            }
          })
        } else if (Array.isArray(this.lancamento[key])) {
          formData.append(key, JSON.stringify(this.lancamento[key]));
        } else {
          if (this.lancamento[key] === null && this.lancamento[key] == 'cliente') {
            this.lancamento[key] = "";
          }
          if (typeof this.lancamento[key] != 'object') {
            formData.append(key, this.lancamento[key]);
          }
        }
      }

      Api.post(`lancamentos/${rota}`, formData, true)
        .then(res => {
          toast.update(notificacao, {
            render: res.data.message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
          })
          this.emitClose();
        })
        .catch((err) => {
          toast.update(notificacao, {
            render: err.response.data.message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
          })
          .finally(() => {
            this.disableFields = false;
          })
        });
    },
    deleteArquivo (index) {
      if (this.action != 'read') {
        this.lancamento.arquivos.splice(index, 1);
      }
    },
    adicionaArquivo() {
      this.laa_arquivos.forEach(arquivo => {
        this.lancamento.arquivos.push(arquivo);
      });
      this.laa_arquivos = [];
    },
    getLancamento() {
      if (!this.lan_uuid) return;
      this.loadingCadastro = true;
      Api.post("lancamentos/buscar", { lan_uuid: this.lan_uuid })
        .then((res) => {
          this.lancamento = res.data;
          this.lan_valor = Formatters.floatToMoney(this.lancamento.lan_valor_bruto);
          this.lan_data = Formatters.formatDate(this.lancamento.lan_data);
          this.calcularDespesa();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCadastro = false;
        });
    },
    getCategoria(lan_tipo = this.lan_tipo) {
      this.loadingCategoria = true;
      Api.get("categorias/select/" + (lan_tipo ? lan_tipo : this.lancamento.lan_tipo))
        .then((res) => {
          this.itemsCategoria = res.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCategoria = false;
        });
    },
    getCarteira() {
      this.loadingCarteira = true;
      Api.get("carteiras/select")
        .then((res) => {
          this.itemsCarteira = res.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCarteira = false;
        });
    },
    getCliente() {
      this.loadingCliente = true;
      Api.get("clientes/select")
        .then((res) => {
          this.itemsCliente = res.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCliente = false;
        });
    },
    abrirDialogDespesas () {
      this.dialogDespesas = true
      this.keyDespesa++;
    },
    calcularDespesa() {
      this.totalDespesa = 0;
      this.lancamento.despesas.forEach(despesa => {
        if (despesa.lde_tipo == 1) {
          this.totalDespesa += Formatters.moneyToFloat(despesa.lde_valor)
        } else {
          this.totalDespesa += (Formatters.moneyToFloat(despesa.lde_valor)/100)*(this.lancamento.lan_valor_bruto)
        }
      });
    },
    fechaDespesa (despesas, total) {
      this.totalDespesa = total;
      this.lancamento.despesas = despesas
      this.dialogDespesas = false;
      this.keyDespesa++;
    }
  },
};
</script>

<style>
.dp__menu {
    position: fixed;
    background: var(--dp-background-color);
    border-radius: var(--dp-border-radius);
    min-width: var(--dp-menu-min-width);
    font-family: var(--dp-font-family);
    font-size: var(--dp-font-size);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid var(--dp-menu-border-color);
    box-sizing: border-box;
} 
.custom-select {
  cursor: pointer;
  color: var(--vp-c-text-2);
  margin: 0;
  display: inline-block;
}
</style>
