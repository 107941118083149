<template>
  <v-card>
    <v-card-title class="yellow-background">
      <v-row no-gutters align="center">
        <span class="text-h5">{{ cardTitle }}</span>
        <v-spacer></v-spacer>
        <v-btn icon flat density="comfortable" @click="emitClose()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>
    <v-form fast-fail ref="formCliente" @submit.prevent="saveCliente" :disabled="action == 'read'">
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              density="compact"
              bg-color="white"
              variant="outlined"
              type="text"
              :rules="rules.getMandatoryRule()"
              label="Nome*"
              v-model="cliente.cli_nome"
              :loading="loadingCadastro"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              density="compact"
              bg-color="white"
              variant="outlined"
              type="text"
              label="CPF/CNPJ"
              v-model="cliente.cli_cpf_cnpj"
              :loading="loadingCadastro"
              :rules="cliente.cli_cpf_cnpj?.length > 0 ? (cliente.cli_cpf_cnpj?.length > 14 ? rules.getCnpjRule() : rules.getCpfRule()) : []"
              v-mask="mask"
              @input="mascara()"
              ref="cpf"
              :key="keyMask"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              density="compact"
              bg-color="white"
              variant="outlined"
              prepend-inner-icon="mdi-email-outline"
              label="Email"
              type="email"
              :rules="cliente.cli_email ? rules.getEmailRule() : []"
              :loading="loadingCadastro"
              v-model="cliente.cli_email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              density="compact"
              bg-color="white"
              variant="outlined"
              type="text"
              label="Telefone"
              prepend-inner-icon="mdi-phone-outline"
              :loading="loadingCadastro"
              v-model="cliente.cli_telefone"
              v-mask-phone.br
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              density="compact"
              bg-color="white"
              variant="outlined"
              type="text"
              label="Segmento"
              :loading="loadingCadastro"
              v-model="cliente.cli_segmento"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row dense class="mt-5">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              density="compact"
              bg-color="white"
              variant="outlined"
              :loading="loadingCadastro"
              label="CEP"
              type="text"
              v-mask-cep
              prepend-inner-icon="mdi-map-marker-outline"
              v-model="cliente.endereco.end_cep"
              @change="buscarCEP(cliente.endereco.end_cep)"
              :hint="cepNotFound ? 'Cep não encontrado' : ''"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              density="compact"
              bg-color="white"
              variant="outlined"
              label="Estado"
              type="text"
              v-model="cliente.endereco.end_estado"
              :loading="loadingCadastro"
              :items="itensEstados"
              @update:modelValue="getCidades(), cliente.endereco.end_cidade = null"
              autocomplete="null"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              density="compact"
              bg-color="white"
              :loading="loadingCadastro"
              variant="outlined"
              label="Cidade"
              type="text"
              v-model="cliente.endereco.end_cidade"
              :items="itensCidades"
              autocomplete="null"
              :disabled="!itensCidades.length"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="8" md="8">
            <v-text-field
              :loading="loadingCadastro"
              density="compact"
              bg-color="white"
              variant="outlined"
              label="Rua"
              type="text"
              v-model="cliente.endereco.end_rua"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              density="compact"
              bg-color="white"
              :loading="loadingCadastro"
              variant="outlined"
              label="Bairro"
              type="text"
              v-model="cliente.endereco.end_bairro"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              density="compact"
              bg-color="white"
              variant="outlined"
              :loading="loadingCadastro"
              label="Complemento"
              type="text"
              v-model="cliente.endereco.end_complemento"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions v-if="action != 'read'">
        <v-btn rounded="xl" @click="emitClose()">Cancelar</v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded="xl" class="blue-background text-white px-4" type="submit" :disabled="disableFields || loadingCadastro">salvar</v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn rounded="xl" @click="emitClose()">Fechar</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import Rules from '@/helpers/rules.js';
import Map from '@/helpers/map.brasil.js';
import axios from 'axios';
import Api from '@/helpers/api';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  props: {
    cli_uuid: String,
    action: String
  },
  data() {
    return {
      loadingCadastro: false,
      rules: Rules,
      disableFields: false,
      itensEstados: Map.getEstados(),
      itensCidades: [],
      cepNotFound: false,
      cardTitle: 'Novo Cliente',
      cliente: { 
        cli_nome: '',
        cli_segmento: null,
        cli_telefone: null,
        cli_email: null,
        cli_uuid: null,
        cli_cpf_cnpj: "",
        endereco: {
          end_cep: null,
          end_rua: null,
          end_bairro: null,
          end_cidade: null,
          end_estado: null,
          end_complemento: null,
        },
      },
      keyMask: 0,
      mask: '000.000.000-0#',
    }
  },
  mounted () {
    if (this.action != 'create') this.getCliente();
    if (this.action == 'edit') this.cardTitle = 'Editar cliente'
    if (this.action == 'read') this.cardTitle = 'Ver cliente'
  },
  methods: {
    mascara () {
      if (this.cliente.cli_cpf_cnpj.length > 14 && this.mask == '000.000.000-0#') {
        this.mask = '00.000.000/0000-00';
        this.keyMask++;
      } else if (this.cliente.cli_cpf_cnpj.length <= 14 && this.mask == '00.000.000/0000-00') {
        this.mask = '000.000.000-0#';
        this.keyMask++;
      }
      this.$refs.cpf.focus();
      return this.mask;
    },
    emitClose () {
      this.$emit('fechaDialog');
    },
    async saveCliente (event) {
      this.disableFields = true;
      const results = await event
      if (!results.valid) {
        this.disableFields = false;
        toast.info("Preencha todos os campos!", {
          position: `bottom-center`
        })
        return
      }
      let notificacao = toast.loading("Salvando cliente");
      let rota = 'novo';
      if (this.action == 'edit') rota = 'editar'
      Api.post(`clientes/${rota}`, this.cliente)
        .then(res => {
          // this.clientes = res.data
          toast.update(notificacao, {
            render: res.data.message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
          })
          this.emitClose();
        })
        .catch(err => {
          toast.update(notificacao, {
            render: err.response.data.message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
          })
        }).finally(() =>{
          this.disableFields = false;
        })
    },
    getCliente () {
      if (!this.cli_uuid) return
      this.loadingCadastro = true
      Api.post('clientes/buscar', { cli_uuid: this.cli_uuid })
        .then(res => {
          this.cliente = res.data
          if (this.cliente.endereco.end_cep) this.getCidades();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCadastro = false;
        })
    },
    getCidades () {
      this.itensCidades = Map.getCidadesByEstadoNome(this.cliente.endereco.end_estado);
    },
    buscarCEP (cep) {
      axios.get(`https://viacep.com.br/ws/${cep.replace('.', '').replace('-', '')}/json/`)
        .then(response => {
          if (response.data.erro) return this.cepNotFound = true
          this.cepNotFound = false
          this.cliente.endereco.end_cep = response.data.cep;
          this.cliente.endereco.end_rua = response.data.logradouro;
          this.cliente.endereco.end_bairro = response.data.bairro;
          this.cliente.endereco.end_complemento = response.data.complemento;
          this.cliente.endereco.end_estado = Map.getEstadoNomeByUf(response.data.uf);
          this.cliente.endereco.end_cidade = response.data.localidade;
          this.getCidades();
        })
        .catch(error => {
          console.error(error);
        })
    }
  }
};
</script>