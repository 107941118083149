<template>
  <app-bar :key="keyAppBar"></app-bar>
  <v-container>
    <v-row dense>
      <v-col cols="12" sm="12" md="12">
        <h1 class="text-center">{{ evento.eve_titulo }}</h1>
      </v-col>
    </v-row>
    <v-stepper
      flat
      elevation="0"
      :items="['Ingressos', 'Identificação', 'Resumo', 'Pagamento']"
      v-model="step"
      :mobile="this.$vuetify.display.name == 'xs' ? true : false"
      :hide-actions="step == 2 || step == 4"
      >
      <template v-slot:actions="{ prev, next }">
        <v-row no-gutters justify="space-between" class="pa-2">
          <v-btn flat @click="voltar(prev)" :disabled="step == 1 || step == 4">Voltar</v-btn>
          <v-btn 
            class="blue-background text-white"
            @click="proximo(next)"
            :disabled="!this.carrinho.length || loadingSubmit"
            :loading="loadingSubmit"
          >
            {{ step == 3 ? 'Confirmar compra' : 'Continuar'}}
          </v-btn>
        </v-row>
      </template>
      <template v-slot:item.1>
        <v-card title="Ingressos" flat>
          <v-card-text>
            <v-row dense v-for="(item, i) in ingressos" :key="i" class="mb-4">
              <v-divider color="orange" thickness="8" ></v-divider>
              <v-col>
                {{ item.evi_nome }} <span v-if="item.evi_quantidade == 0" class="text-red text-overline">(Esgotado)</span>
              </v-col>
              <v-col>
                {{ formatters.floatToMoney(item.evi_valor) }}
              </v-col>
              <v-col>
                <div class="text-right">
                  <v-icon @click="adicionaIngresso(item)" :color="(item.quantidade >= 5 || item.evi_quantidade <= item.quantidade) ? 'grey' : '' ">mdi-plus-circle-outline</v-icon>
                  <span class="mx-2">{{ item.quantidade }}</span>
                  <v-icon @click="adicionaIngresso(item, true)" :color="item.quantidade == 0 ? 'grey' : ''">mdi-minus-circle-outline</v-icon>
                </div>
              </v-col>
            </v-row>
            <v-row class="py-6" v-if="!ingressos.length">
              <v-col>
                Nenhum ingresso disponível
              </v-col>
            </v-row>
          </v-card-text>
          Total: <span v-text="totalCarinho()"></span>
        </v-card>
      </template>

      <template v-slot:item.2>
        <v-card title="Identificação" flat>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-form validate-on="blur"  ref="formLogin" @submit.prevent="signIn">
                <h3 class="d-flex justify-center">Entrar</h3>
                <v-text-field
                  class="my-1"
                  single-line
                  density="compact"
                  bg-color="white"
                  variant="outlined"
                  prepend-inner-icon="mdi-email"
                  :loading="loadingSubmit"
                  label="Email"
                  type="email"
                  :rules="rules.getEmailRule()"
                  v-model="login.usu_email"
                ></v-text-field>
                <v-text-field
                  single-line
                  class="my-1"
                  density="compact"
                  :loading="loadingSubmit"
                  bg-color="white"
                  variant="outlined"
                  prepend-inner-icon="mdi-lock"
                  :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="showPassword = !showPassword"
                  v-model="login.usu_senha"
                  label="Senha"
                  :type="!showPassword ? 'password' : 'text'"
                  :rules="rules.getTextLengthRule(6)"
                ></v-text-field>
                <v-btn block class="mt-2 blue-background text-white" type="submit" :loading="loadingSubmit" :disabled="loadingSubmit">
                  Entrar
                </v-btn>
              </v-form>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="12" md="6">
              <v-form validate-on="blur"  ref="formCadastro" @submit.prevent="signUp">
                <h3 class="d-flex justify-center">Criar conta</h3>
                <v-text-field
                  class="my-1"
                  single-line
                  density="compact"
                  :loading="loadingSubmit"
                  bg-color="white"
                  variant="outlined"
                  prepend-inner-icon="mdi-account"
                  label="Nome"
                  :rules="rules.getMandatoryRule()"
                  v-model="cadastro.usu_nome"
                ></v-text-field>
                <v-text-field
                  class="my-1"
                  single-line
                  :loading="loadingSubmit"
                  density="compact"
                  bg-color="white"
                  variant="outlined"
                  prepend-inner-icon="mdi-email"
                  label="Email"
                  :rules="rules.getEmailRule()"
                  v-model="cadastro.usu_email"
                  autocomplete="off"
                ></v-text-field>
                <v-text-field
                  :loading="loadingSubmit"
                  single-line
                  class="my-1"
                  density="compact"
                  bg-color="white"
                  variant="outlined"
                  prepend-inner-icon="mdi-lock"
                  :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="showPassword = !showPassword"
                  v-model="cadastro.usu_senha"
                  label="Senha"
                  :type="!showPassword ? 'password' : 'text'"
                  :rules="rules.getTextLengthRule(6)"
                ></v-text-field>
                <v-text-field
                  class="my-1"
                  density="compact"
                  bg-color="white"
                  variant="outlined"
                  type="text"
                  label="CPF/CNPJ"
                  v-model="cadastro.usu_cpf_cnpj"
                  :loading="loadingSubmit"
                  :rules="cadastro.usu_cpf_cnpj.length > 14 ? rules.getCnpjRule() : rules.getCpfRule()"
                  v-mask="mask"
                  @input="mascara()"
                  ref="cpf"
                  :key="keyMask"
                ></v-text-field>
                <v-text-field
                  :rules="rules.getPhoneRule()"
                  density="compact"
                  class="my-1"
                  bg-color="white"
                  variant="outlined"
                  type="text"
                  label="Telefone"
                  prepend-inner-icon="mdi-phone-outline"
                  :loading="loadingSubmit"
                  v-model="cadastro.usu_telefone"
                  v-mask-phone.br
                ></v-text-field>
                <v-btn block class="mt-2 blue-background text-white" type="submit" :loading="loadingSubmit" :disabled="loadingSubmit">
                  Criar conta
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </template>
  
      <template v-slot:item.3>
        <v-card title="Resumo" flat>
          <v-form validate-on="blur"  ref="formFinalizar">
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  variant="solo"
                  flat
                  density="compact"
                  bg-color="white"
                  prepend-inner-icon="mdi-account"
                  label="Nome"
                  :rules="rules.getMandatoryRule()"
                  :loading="loadingSubmit"
                  v-model="usuario.usu_nome"
                ></v-text-field>
                <v-text-field
                  variant="solo"
                  flat
                  density="compact"
                  bg-color="white"
                  prepend-inner-icon="mdi-email"
                  label="Email"
                  :rules="rules.getEmailRule()"
                  v-model="usuario.usu_email"
                  :loading="loadingSubmit"
                  autocomplete="off"
                ></v-text-field>
                <v-text-field
                  variant="solo"
                  flat
                  class="my-1"
                  density="compact"
                  bg-color="white"
                  type="text"
                  label="CPF/CNPJ"
                  v-model="usuario.usu_cpf_cnpj"
                  :loading="loadingSubmit"
                  :rules="usuario.usu_cpf_cnpj.length > 14 ? rules.getCnpjRule() : rules.getCpfRule()"
                  v-mask="mask"
                  @input="mascaraFinalizar()"
                  ref="cpff"
                  :key="keyMask"
                ></v-text-field>
                <v-text-field
                  variant="solo"
                  flat
                  :rules="rules.getPhoneRule()"
                  density="compact"
                  class="my-1"
                  bg-color="white"
                  type="text"
                  label="Telefone"
                  prepend-inner-icon="mdi-phone-outline"
                  :loading="loadingSubmit"
                  v-model="usuario.usu_telefone"
                  v-mask-phone.br
                ></v-text-field>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" sm="12" md="6">
                <v-list>
                  <v-list-item v-for="(ing, i) in carrinho" :key="i">
                    <div class="d-flex justify-space-between">
                      <span>{{ ing.quantidade }}x {{ ing.evi_nome }}</span>
                      <span>{{ formatters.floatToMoney(ing.evi_valor * ing.quantidade) }}</span> 
                    </div>
                    <v-divider></v-divider>
                  </v-list-item>
                  <v-list-item >
                    <div class="d-flex justify-space-between">
                      <span>Total:</span>
                      <span v-text="totalCarinho()"></span>
                    </div>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item >
                    <div class="d-flex justify-space-between my-4">
                      <span>Parcelamento</span>
                    </div>
                    <v-select
                      density="compact"
                      bg-color="white"
                      variant="outlined"
                      type="text"
                      label="Parcelas"
                      :items="itemsPagamento"
                      v-model="parcelas"
                    ></v-select>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </template>
      <template v-slot:item.4>
        <v-card title="Pagamento">
          <v-row no-gutters justify="center">
            <v-col cols="12" sm="12" md="6">
              <v-btn :href="cobranca.invoiceUrl" target="_blank" class="blue-background text-white my-1">Acessar cobrança</v-btn>
              <br/>
              <v-btn :href="cobranca.bankSlipUrl" target="_blank" class="blue-background text-white my-1">Acessar boleto</v-btn>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="12" md="6">
              <v-list>
                <v-list-item v-for="(ing, i) in carrinho" :key="i">
                  <div class="d-flex justify-space-between">
                    <span>{{ ing.quantidade }}x {{ ing.evi_nome }}</span>
                    <span>{{ formatters.floatToMoney(ing.evi_valor * ing.quantidade) }}</span> 
                  </div>
                  <v-divider></v-divider>
                </v-list-item>
                <v-list-item >
                  <div class="d-flex justify-space-between">
                    <span>Total à pagar:</span>
                    <span v-text="totalCarinho()"></span>
                  </div>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-stepper>

  </v-container>
</template>

<script>
import Api from "@/helpers/api";
import Formatters from "@/helpers/formatters";
import Rules from "@/helpers/rules.js";
import Login from '@/helpers/login';
import { toast } from 'vue3-toastify';
import AppBar from "./AppBar.vue";
export default {
  components: {
    AppBar
  },
  data() {
    return {
      rules: Rules,
      formatters: Formatters,
      isLoggedIn: Login.isLoggedIn(),
      keyAppBar: 0,
      keyMask: 0,
      step: 1, // 1
      mask: '000.000.000-0#',
      loadingSubmit: false,
      search: "",
      evento: [],
      ingressos: [],
      carrinho: [],
      parcelas: 1,
      itemsPagamento: [],
      usuario: {
        usu_nome: "",
        usu_email: "",
        usu_cpf_cnpj: "",
        usu_telefone: null
      },
      showPassword: false,
      login: {
        usu_email: "",
        usu_senha: ""
      },
      cadastro: {
        usu_nome: "",
        usu_email: "",
        usu_senha: "",
        usu_cpf_cnpj: "",
        usu_telefone: null
      },
      cobranca: []
    };
  },
  mounted() {
    this.getEventos();
    this.getIngressos();
    if (Login.verifyUserType('user')) this.getUsuario();
  },
  methods: {
    voltar(prev) {
      if (this.step == 1 || this.step == 4) return;
      if (this.step == 3 && Login.verifyUserType('user')) {
        this.step = 1;
        return;
      }
      prev();
    },
    proximo(next) {
      this.calculaCobranca();
      if (this.step == 1) {
        if (!this.carrinho.length) {
          return
        }
        if (Login.verifyUserType('user')) {
          this.step = 3;
          return;
        }
      }
      if (this.step == 3) {
        this.finalizarCompra();
        return
      }
      next();
    },
    calculaCobranca () {
      let total = this.totalCarinho(false);
      this.itemsPagamento = [];
      for (let i = 1; i <= 10; i++) {
        let parcela = total / i;
        this.itemsPagamento.push({
          title: `${i}x de ${Formatters.floatToMoney(parcela)}`,
          value: i
        });
      }
    },
    mascara () {
      if (this.cadastro.usu_cpf_cnpj.length > 14 && this.mask == '000.000.000-0#') {
        this.mask = '00.000.000/0000-00';
        this.keyMask++;
      } else if (this.cadastro.usu_cpf_cnpj.length <= 14 && this.mask == '00.000.000/0000-00') {
        this.mask = '000.000.000-0#';
        this.keyMask++;
      }
      this.$refs.cpf.focus();
      return this.mask;
    },
    mascaraFinalizar () {
      if (this.usuario.usu_cpf_cnpj.length > 14 && this.mask == '000.000.000-0#') {
        this.mask = '00.000.000/0000-00';
        this.keyMask++;
      } else if (this.usuario.usu_cpf_cnpj.length <= 14 && this.mask == '00.000.000/0000-00') {
        this.mask = '000.000.000-0#';
        this.keyMask++;
      }
      this.$refs.cpff.focus();
      return this.mask;
    },
    totalCarinho(format = true) {
      let total = 0;
      this.carrinho.map((car) => {
        let valor = car.quantidade * car.evi_valor;
        total += valor;
      });
      return format ? Formatters.floatToMoney(total) : total;
    },
    adicionaIngresso(ingresso, minus = false) {
      let mesmoIngresso = this.carrinho.filter(
        (ing) => ing.evi_uuid == ingresso.evi_uuid
      );
      if (mesmoIngresso.length) {
        if (minus) {
          if (ingresso.quantidade == 1) this.carrinho.splice(this.carrinho.indexOf(mesmoIngresso[0]), 1);
          mesmoIngresso[0].quantidade--;
        } else {
          if (ingresso.quantidade >= 5 || ingresso.evi_quantidade <= ingresso.quantidade) return;
          mesmoIngresso[0].quantidade++;
        }
      } else {
        if (!minus && ingresso.evi_quantidade > 0) {
          ingresso.quantidade++;
          this.carrinho.push(ingresso);
        }
      }
    },
    finalizarCompra() {
      this.loadingSubmit = true;
      this.$refs.formFinalizar.validate();
      if (!this.$refs.formFinalizar.isValid) return this.loadingSubmit = false;
      let data = {
        usuario: this.usuario,
        carrinho: this.carrinho,
        parcelas: this.parcelas,
        total: this.totalCarinho(false),
        evento: {
          eve_uuid: this.evento.eve_uuid,
          eve_titulo: this.evento.eve_titulo
        }
      }
      Api.post("compras/finalizar", data)
        .then((res) => {
          this.cobranca = res.data;
          this.step++;
        })
        .catch(err => {
          toast.error(err.response.data.message)
        })
        .finally(()=> {
          this.loadingSubmit = false;
        });
    },
    signUp() {
      this.loadingSubmit = true;
      this.$refs.formCadastro.validate();
      if (!this.$refs.formCadastro.isValid) return this.loadingSubmit = false;
      Api.post("usuarios/novo", this.cadastro)
        .then((response) => {
          Login.saveToken(response.data.authorisation.token, 'user');
          this.getUsuario();
          this.step++;
          this.keyAppBar++;
        })
        .catch(err => {
          toast.error(err.response.data.message)
        })
        .finally(()=> {
          this.loadingSubmit = false;
        });
    },
    signIn() {
      this.loadingSubmit = true;
      this.$refs.formLogin.validate();
      if (!this.$refs.formLogin.isValid) return this.loadingSubmit = false;
      Api.post("usuarios/login", this.login)
        .then((response) => {
          Login.saveToken(response.data.authorisation.token, 'user');
          this.getUsuario();
          this.keyAppBar++;
          this.step++;
        })
        .catch(err => {
          toast.error(err.response.data.message)
        })
        .finally(()=> {
          this.loadingSubmit = false;
        });
    },
    getUsuario() {
      Api.get("usuarios/usuario")
        .then((res) => {
          this.usuario = res.data.user;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        });
    },
    getIngressos() {
      this.loadingListagem = true;
      Api.post("compras/ingressos/" + this.$route.params.eve_uuid)
        .then((res) => {
          this.ingressos = res.data;
          this.ingressos.map((ingresso) => {
            ingresso.quantidade = 0;
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        });
    },
    getEventos() {
      this.loadingListagem = true;
      Api.post("compras/buscar/" + this.$route.params.eve_uuid)
        .then((res) => {
          this.evento = res.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        });
    }
  },
};
</script>
