import moment from "moment";

class Formatters {
    static floatToMoney (float) {
        let money = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        })

        return money.format(float);
    }

    static moneyToFloat(str) {
        if (!str) return
        if (typeof str == 'number') return str;
        return Number(str.split('.').join('').split(',').join('.'));
    }

    static formatDate (date, format = 'L') {
        return moment(date).format(format);
    }

    static formatDateToEn(date, input = "DD/MM/YYYY", format = "YYYY-MM-DD") {
        var data = moment(date, input);
        if (!data.isValid()) return date;
        return data.format(format)
      }
}

export default Formatters;