import formatters from '@/helpers/formatters';

class Rules {
    static getEmailRule() {
        return [v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Email inválido"]
    }
    static getDateRule() {
        return [v => /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/.test(v) || "Data inválida"]
    }
    static getCpfRule() {
        return [v => validateCPF(v) || "CPF inválido"]
    }
    static getCnpjRule() {
        return [v => validateCNPJ(v) || "CNPJ inválido"]
    }
    static getPhoneRule() {
        return [v => validatePhone(v) || "Telefone inválido"]
    }
    static getTextLengthRule (length) {
        return [v => v.trim().length >= length || `Mínimo ${length} caractéres`]
    }
    static getMandatoryRule () {
        return [v => (!!v && !!v.trim()) || `Campo obrigatório`]
    }
    static getSelectMandatoryRule () {
        return [v => !!v || `Campo obrigatório`]
    }
    static getMonetaryRule (value = 0) {
        return [v => formatters.moneyToFloat(v) > value || `Valor deve ser maior que R$${value}`]
    }
    static getRangeRule (end, start = 1) {
        return [v => (v >= start && v <= end) || `Valor deve ser entre ${start} e ${end}`]
    }
    static getQuantityRule (min = 1) {
        return [v => (v >= min) || `Quantidade deve ser maior que ${min}`]
    }
}

export default Rules;

function validateCPF(cpf) {
    var cpfRegex = /^(?:(\d{3}).(\d{3}).(\d{3})-(\d{2}))$/;
    if (!cpfRegex.test(cpf)) return false;

    var numeros = cpf.match(/\d/g).map(Number);
    var soma = numeros.reduce((acc, cur, idx) => {
      if (idx < 9) return acc + cur * (10 - idx);
      return acc;
    }, 0);

    var resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== numeros[9]) return false;

    soma = numeros.reduce((acc, cur, idx) => {
      if (idx < 10) {
        return acc + cur * (11 - idx);
      }
      return acc;
    }, 0);

    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== numeros[10]) return false;

    return true;
}

function validateCNPJ (cnpj) {
    var b = [ 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ]
    var c = String(cnpj).replace(/[^\d]/g, '')
    
    if(c.length !== 14)
        return false

    if(/0{14}/.test(c))
        return false

    for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
    if(c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
    if(c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    return true
}

function validatePhone(telefone) {
    if (!telefone) return false
    telefone = telefone.replace(/\D/g, '');

    if (!(telefone.length >= 10 && telefone.length <= 11)) return false;

    if (telefone.length == 11 && parseInt(telefone.substring(2, 3)) != 9) return false;

    for (var n = 0; n < 10; n++) {
        if (telefone == new Array(11).join(n) || telefone == new Array(12).join(n)) return false;
    }
    var codigosDDD = [11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 64, 63, 65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99];
    if (codigosDDD.indexOf(parseInt(telefone.substring(0, 2))) == -1) return false;

    if (new Date().getFullYear() < 2017) return true;
    if (telefone.length == 10 && [2, 3, 4, 5, 7].indexOf(parseInt(telefone.substring(2, 3))) == -1) return false;

    return true;
}