<template>
  <v-card max-height="570">
    <v-card-title class="yellow-background">
      <v-row no-gutters align="center">
        <span class="text-h5">Participantes - {{ eve_titulo }}</span>
        <v-spacer></v-spacer>
        <v-btn icon flat density="comfortable" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-table height="370" v-if="participantes && participantes.length">
        <thead>
          <tr>
            <th class="text-left">Nome</th>
            <th class="text-left">Email</th>
            <th class="text-left">Telefone</th>
            <th class="text-left">Código</th>
            <th class="text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in participantes[1]" :key="item">
            <td>{{ item.ech_nome }}</td>
            <td>{{ item.ech_email }}</td>
            <td>{{ item.ech_telefone }}</td>
            <td>{{ item.evp_codigo }}</td>
            <td class="text-center pr-0">
              <!-- CHECK-IN FEITO-->
              <v-chip color="green">
                <v-icon color="green" icon="mdi-check-all" class="mr-2"></v-icon>
                <span class="text-black">Check-in feito</span>
              </v-chip>
            </td>
          </tr>
          <tr v-for="item in participantes[0]" :key="item">
            <td>{{ item.usuario.usu_nome }}</td>
            <td>{{ item.usuario.usu_email }}</td>
            <td>{{ item.usuario.usu_telefone }}</td>
            <td>{{ item.evp_codigo }}</td>
            <td class="text-center pr-0">
              <!-- CHECK-IN FEITO-->
              <v-chip color="green" v-if="item.evp_checkin == 1">
                <v-icon color="green" icon="mdi-check-all" class="mr-2"></v-icon>
                <span class="text-black">Check-in feito</span>
              </v-chip>
              <!-- PARTICIPANTE CONFIRMADO-->
              <v-chip color="green" v-else-if="item.evc_confirmado == 2">
                <v-icon color="green" icon="mdi-check" class="mr-2"></v-icon>
                <span class="text-black">Confirmado</span>
              </v-chip>
              <!-- PARTICIPANTE PENDENTE-->
              <v-chip color="orange" v-else-if="item.evc_confirmado == 1">
                <v-icon color="orange" icon="mdi-alert" class="mr-2"></v-icon>
                <span class="text-black">Pendente</span>
              </v-chip>
              <!-- PARTICIPANTE NÂO CONFIRMADO-->
              <v-chip color="red" v-else-if="item.evc_confirmado == 0">
                <v-icon color="red" icon="mdi-close" class="mr-2"></v-icon>
                <span class="text-black">Vencido</span>
              </v-chip>
            </td>
          </tr>
        </tbody>
      </v-table>
      <v-row v-if="participantes && !participantes.length && !loadingListagem" no-gutters justify="center">
        <span class="mt-2 text-grey-darken-2">Nenhum participante encontrado!</span>
      </v-row>
      <v-row v-if="participantes && !participantes.length && loadingListagem" no-gutters justify="center">
        <v-progress-circular indeterminate class="mt-3" width="5" size="37" color="primary"></v-progress-circular>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn flat density="comfortable" class="ml-4" @click="$emit('close')">
        Fechar
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn rounded class="mr-4 px-4 blue-background text-white" @click="dialogParticipante = true" :disabled="loadingListagem" :loading="loadingListagem">
        Adicionar participante
        <v-icon class="ml-1">mdi-account</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn rounded class="mr-4 px-4 blue-background text-white" @click="imprimirParticipantes()" :disabled="loadingListagem" :loading="loadingListagem" v-if="participantes.length">
        Imprimir confirmados
        <v-icon class="ml-1">mdi-download</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
  <v-dialog v-model="dialogParticipante" width="450">
    <v-form ref="formNovoParticipante" @submit.prevent="adicionarParticipantes">
      <v-card>
        <v-card-title class="yellow-background">
          <v-row no-gutters align="center">
            <span class="text-h5">Novo Participante</span>
            <v-spacer></v-spacer>
            <v-btn icon flat density="comfortable" @click="dialogParticipante = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                prepend-inner-icon="mdi-account"
                label="Nome"
                :rules="rules.getMandatoryRule()"
                :loading="loadingSubmit"
                v-model="usuario.usu_nome"
              ></v-text-field>
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                prepend-inner-icon="mdi-email"
                label="Email"
                :rules="rules.getEmailRule()"
                v-model="usuario.usu_email"
                :loading="loadingSubmit"
                autocomplete="off"
              ></v-text-field>
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                :rules="rules.getPhoneRule()"
                class="my-1"
                type="text"
                label="Telefone"
                prepend-inner-icon="mdi-phone-outline"
                :loading="loadingSubmit"
                v-model="usuario.usu_telefone"
                v-mask-phone.br
              ></v-text-field>
            </v-col>
          </v-row>
          <span class="text-red text-caption">Pagamento será considerado <b>PAGO</b> e check-in realizado!</span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat density="comfortable" class="ml-4" @click="dialogParticipante = false">
          Fechar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded class="mr-4 px-4 blue-background text-white" type="submit" :disabled="loadingParticipante" :loading="loadingParticipante">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template> 

<script>
import Api from '@/helpers/api';
import { toast } from 'vue3-toastify';
import moment from 'moment';
import Rules from '@/helpers/rules'
export default {
  props: [
    'eve_uuid',
    'eve_titulo'
  ],
  emits: ['close'],
  data() {
    return {
      rules: Rules,
      participantes: [],
      dialogConfirmar: false,
      loadingListagem: false,
      loadingParticipante: false,
      dialogParticipante: false,
      usuario: {
        usu_nome: "",
        usu_email: "",
        usu_telefone: null,
        eve_uuid: this.eve_uuid
      }
    }
  },
  mounted() {
    this.getParticipantes();
  },
  methods: {
    adicionarParticipantes() {
      this.loadingParticipante = true;
      Api.post("checkin/adicionarParticipante", this.usuario)
        .then(res => {
          toast.success(res.data.message);
          this.getParticipantes();
          this.dialogParticipante = false;
          this.usuario = {
            usu_nome: "",
            usu_email: "",
            usu_telefone: null,
            eve_uuid: this.eve_uuid
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingParticipante = false;
        });
    },
    getParticipantes() {
      this.loadingListagem = true;
      Api.get("eventos/participantes/" + this.eve_uuid)
        .then((res) => {
          this.participantes = res.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        });
    },
    imprimirParticipantes() {
      this.loadingListagem = true;
      Api.getBlob("eventos/participantes/imprimir/" + this.eve_uuid)
        .then(res => {
          toast.success('Lista gerada!');
          let arquivoNome = 'Participantes_' + this.eve_titulo + '_' + moment().format('L_H:mm') + '.pdf';
          var arquivoURL = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
          var arquivoLink = document.createElement('a');
          arquivoLink.href = arquivoURL;
          arquivoLink.setAttribute('download', arquivoNome);
          document.body.appendChild(arquivoLink);
          arquivoLink.click();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        });
    },
  }
};
</script>
