<template>
  <div>
    <v-card>
      <v-card-title class="yellow-background">
        <v-row no-gutters align="center">
          <span class="text-h5">{{ cardTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn icon flat density="comfortable" @click="emitClose()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-form fast-fail ref="formRelatorio" @submit.prevent="saveRelatorio" :disabled="action == 'read'">
        <v-card-text>
          <v-row no-gutters justify="space-around" align="center">
            <v-menu v-model="menuMes">
              <template v-slot:activator="{ props }">
                <v-btn class="blue-background text-white" rounded v-bind="props">
                  {{ rel_mes?.name || "Selecione o mês"}}
                </v-btn>
              </template>

              <v-card width="500">
                <v-card-text>
                  <v-row no-gutters>
                    <v-col
                    cols="6"
                    sm="4"
                    md="4"
                    v-for="(item, index) in meses"
                    :key="index"
                    class="pa-1"
                    >
                    <v-btn block variant="outlined" @click="rel_mes = item">{{ item.name }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              </v-card>
            </v-menu>
            <v-menu v-model="menuAno">
              <template v-slot:activator="{ props }">
                <v-btn class="blue-background text-white" rounded v-bind="props">
                  {{ rel_ano || "Selecione o Ano"}}
                </v-btn>
              </template>

              <v-card width="250">
                <v-card-text>
                  <v-row no-gutters>
                    <v-col
                    cols="6"
                    sm="4"
                    md="4"
                    v-for="(item, index) in anos"
                    :key="index"
                    class="pa-1"
                    >
                    <v-btn variant="outlined" @click="rel_ano = item">{{ item }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              </v-card>
            </v-menu>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="action != 'read'">
          <v-btn rounded="xl" @click="emitClose()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded="xl" class="blue-background text-white px-4" type="submit" :disabled="disableFields">salvar</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn rounded="xl" @click="emitClose()">Fechar</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import Api from '@/helpers/api';
import Rules from '@/helpers/rules.js';
import formatters from '@/helpers/formatters';
import '@vuepic/vue-datepicker/dist/main.css'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  props: {
    rel_uuid: String,
    action: String,
  },
  data() {
    return {
      menuMes: false,
      menuAno: false,
      rules: Rules,
      meses: [
        {name: 'Janeiro', value: '01'},
        {name: 'Fevereiro', value: '02'},
        {name: 'Março', value: '03'},
        {name: 'Abril', value: '04'},
        {name: 'Maio', value: '05'},
        {name: 'Junho', value: '06'},
        {name: 'Julho', value: '07'},
        {name: 'Agosto', value: '08'},
        {name: 'Setembro', value: '09'},
        {name: 'Outubro', value: '10'},
        {name: 'Novembro', value: '11'},
        {name: 'Dezembro', value: '12'}
      ],
      anos: [],
      loadingCadastro: false,
      disableFields: false,
      cardTitle: "Novo Relatorio",
      rel_saldo_inicial: 0,
      rel_ano: null, 
      rel_mes: null, 
      relatorio: {
        rel_data: null
      }
    };
  },
  mounted() {
    if (this.action != "create") this.getRelatorio();
    if (this.action == "edit") this.cardTitle = "Editar Relatorio";
    if (this.action == "read") this.cardTitle = "Ver Relatorio";
    this.geraAnos();
  },
  methods: {
    geraAnos() {
      this.anos = Array.from({ length: 9 }, (_, i) => (2023) + i);
    },
    setNextViewMode () {
      this.viewMode = 'months';
    }, 
    emitClose () {
      this.$emit('fechaDialog');
    },
    async saveRelatorio () {
      this.disableFields = true;
      if (!this.rel_ano || !this.rel_mes) {
        this.disableFields = false;
        toast.info("Selecione mês/ano!", {
          position: `bottom-center`
        })
        return
      }
      this.relatorio.rel_data = formatters.formatDateToEn(("01/"+this.rel_mes.value+"/"+this.rel_ano));
      let notificacao = toast.loading("Salvando relatorio");
      Api.post(`relatorios/novo`, this.relatorio)
        .then(res => {
          toast.update(notificacao, {
            render: res.data.message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
          })
          this.emitClose();
        })
        .catch(err => {
          toast.update(notificacao, {
            render: err.response.data.message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
          })
        })
      setTimeout(() => {
        this.disableFields = false;
      }, 1000);
    },
    getRelatorio() {
      if (!this.rel_uuid) return
      this.loadingCadastro = true
      Api.post('relatorios/buscar', { rel_uuid: this.rel_uuid })
        .then(res => {
          this.relatorio = res.data
          this.rel_saldo_inicial = formatters.floatToMoney(this.relatorio.rel_saldo_inicial);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCadastro = false;
        })
    },
  },
};
</script>

<style>
.dp__menu {
  position: fixed;
  background: var(--dp-background-color);
  border-radius: var(--dp-border-radius);
  min-width: var(--dp-menu-min-width);
  font-family: var(--dp-font-family);
  font-size: var(--dp-font-size);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid var(--dp-menu-border-color);
  box-sizing: border-box;
} 
.custom-select {
cursor: pointer;
color: var(--vp-c-text-2);
margin: 0;
display: inline-block;
}
</style>