<template>
  <v-dialog v-model="dialogDespesas" width="700">
    <v-card>
      <v-card-title class="yellow-background">
        <v-row no-gutters align="center">
          <span class="text-h5">Despesas</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            flat
            density="comfortable"
            @click="dialogDespesas = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form
          validate-on="blur"
          ref="formDespesa"
          @submit.prevent="saveDespesa"
          :disabled="disableFields"
        >
          <v-row dense justify="end" v-if="action != 'read'">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                type="text"
                label="Descrição*"
                v-model="despesa.lde_descricao"
                :rules="rules.getMandatoryRule()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-if="despesa.lde_tipo == 1"
                density="compact"
                bg-color="white"
                variant="outlined"
                type="tel"
                label="Valor*"
                v-model="despesa.lde_valor"
                :rules="rules.getMonetaryRule()"
                v-mask-decimal.br="2"
                prefix="R$"
              ></v-text-field>
              <v-text-field
                v-if="despesa.lde_tipo == 2"
                density="compact"
                bg-color="white"
                variant="outlined"
                type="number"
                label="Valor*"
                v-model="despesa.lde_valor"
                :rules="rules.getRangeRule(100)"
                prefix="%"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                density="compact"
                variant="outlined"
                :items="itemsDespesaTipo"
                v-model="despesa.lde_tipo"
                label="Tipo"
                @update:model-value="despesa.lde_valor = 0"
              ></v-select>
            </v-col>
            <v-btn
              color="primary"
              rounded="xl"
              type="submit"
              :disabled="disableFields || action == 'read'"
            >
              Adicionar
            </v-btn>
          </v-row>
        </v-form>
        <v-row dense justify="center">
          <v-col cols="12" sm="12" md="12">
            <v-table>
              <thead>
                <tr>
                  <th class="text-left">Descrição</th>
                  <th class="text-center">Valor</th>
                  <th class="text-right"></th>
                </tr>
              </thead>
            
              <tbody>
                <tr v-for="(item, index) in lan_despesas" :key="index">
                  <td>{{ item.lde_descricao }}</td>
                  <td class="text-center">{{ item.lde_tipo == 1 ? formatters.floatToMoney(item.lde_valor) : `${item.lde_valor}%` }}</td>
                  <td class="text-right pr-0" v-if="action != 'read'">
                    <!-- Delete -->
                    <v-tooltip text="Excluir" location="top">
                      <template v-slot:activator="{ props }">
                        <v-btn icon flat density="comfortable" @click="deleteDespesa(index)">
                          <v-icon v-bind="props" icon="mdi-delete"></v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>
                    <!-- End Delete -->
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-col>  
          <span class="text-subtitle-2 text-grey-darken-1 mb-3" v-if="!lan_despesas.length">
            Nenhuma despesa adicionada
          </span>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions v-if="action != 'read'">
        <v-btn rounded="xl" @click="dialogDespesas = false">Fechar</v-btn>
        <v-spacer></v-spacer>
        <span>Total: {{ formatters.floatToMoney(totalDespesas) }}</span>
        <v-spacer></v-spacer>
        <v-btn rounded="xl" color="primary" @click="fecharDialogDespesas()">Salvar</v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn rounded="xl" @click="dialogDespesas = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Formatters from "@/helpers/formatters";
import Rules from "@/helpers/rules.js";
export default {
  props: {
    valorTotal: [Number, String],
    abrirDialog: Boolean,
    despesas: Object,
    action: String
  },
  data() {
    return {
      rules: Rules,
      formatters: Formatters,
      dialogDespesas: this.abrirDialog,
      disableFields: false,
      lan_despesas: this.despesas,
      despesa: {
        lde_descricao: "",
        lde_valor: "",
        lde_tipo: 1,
      },
      totalDespesas: 0,
      itemsDespesaTipo: [
        { title: "R$", value: 1 },
        { title: "%", value: 2 },
      ],
    };
  },
  mounted() {
    this.calcularDespesa()
  },
  methods: {
    deleteDespesa(index) {
      this.lan_despesas.splice(index, 1);
      this.calcularDespesa()
    },
    fecharDialogDespesas() {
      this.dialogDespesas = false;
      this.$emit("fecharDialog", this.lan_despesas, this.totalDespesas);
    },
    calcularDespesa() {
      this.totalDespesas = 0;
      this.lan_despesas.forEach(despesa => {
        if (despesa.lde_tipo == 1) {
          this.totalDespesas += Formatters.moneyToFloat(despesa.lde_valor)
        } else {
          this.totalDespesas += (Number(despesa.lde_valor)/100)*(Formatters.moneyToFloat(this.valorTotal))
        }
      });
    },
    async saveDespesa(event) {
      this.disableFields = true;
      const results = await event;
      if (!results.valid) return this.disableFields = false;
      this.despesa.lde_valor = Formatters.moneyToFloat(this.despesa.lde_valor)
      this.lan_despesas.push(this.despesa);
      
      this.calcularDespesa();
      
      this.despesa = {
        lde_descricao: "",
        lde_valor: "",
        lde_tipo: 1
      }
      
      setTimeout(() => {
        this.disableFields = false;
      }, 1000);
    },
  },
};
</script>
