<template>
  <v-card variant="outlined" rounded="lg" color="grey">
    <v-card-text>
      <v-table>
        <thead>
          <tr>
            <th class="text-left">Data</th>
            <th class="text-left">Código</th>
            <th class="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in relatorios" :key="item" v-show="relatorios.length">
            <td class="text-left text-capitalize">{{ formatter.formatDate(item.rel_data, 'MMMM YYYY') }}</td>
            <td class="text-left" ref="refCodigo" @click="copy(item.rel_codigo, false)">
              <v-tooltip text="Copiar código" location="top">
                <template v-slot:activator="{ props }">
                  <v-btn flat v-bind="props" class="ml-n4">
                    {{ item.rel_codigo }}
                    <v-icon icon="mdi-content-copy" class="ml-1"></v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </td>
            <td class="text-right pr-0">
              <!-- Read -->
              <v-tooltip text="Ver relatório" location="top">
                <template v-slot:activator="{ props }">
                  <v-btn icon flat density="comfortable" :to="`/relatorio?codigo=${item.rel_codigo}`">
                    <v-icon v-bind="props" icon="mdi-eye"></v-icon>
                  </v-btn>
                </template>
              </v-tooltip>

              <!-- Copiar link -->
              <v-tooltip text="Copiar link" location="top">
                <template v-slot:activator="{ props }">
                  <v-btn icon flat density="comfortable" @click="copy(item.rel_codigo, true)">
                    <v-icon v-bind="props" icon="mdi-content-copy"></v-icon>
                  </v-btn>
                </template>
              </v-tooltip>

              <!-- Gerar PDF -->
              <v-tooltip text="Gerar PDF" location="top">
                <template v-slot:activator="{ props }">
                  <v-btn icon flat density="comfortable" @click="gerarRelatorio(item.rel_uuid)" :loading="disableRelatorio" :disabled="disableRelatorio">
                    <v-icon v-bind="props" icon="mdi-file-pdf"></v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
              
              <!-- End Read -->
              <v-tooltip text="Excluir" location="top">
                <template v-slot:activator="{ props }">
                  <v-btn icon flat density="comfortable" @click="$emit('actions', 'delete', item.rel_uuid, 'este relatório')">
                    <v-icon v-bind="props" icon="mdi-delete"></v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </v-table>
      <v-row v-show="!relatorios.length && !loadingListagem" no-gutters justify="center">
        <span class="mt-2 text-grey-darken-2">Nenhum relatório encontrado!</span>
      </v-row>
      <v-row v-show="!relatorios.length && loadingListagem" no-gutters justify="center">
        <v-progress-circular indeterminate class="mt-3" width="5" size="37" color="primary"></v-progress-circular>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Api from "@/helpers/api.js";
import Formatters from "@/helpers/formatters.js";
import { toast } from 'vue3-toastify';
import moment from 'moment';
export default {
  data() {
    return {
      api: Api,
			formatter: Formatters,
      relatorios: [],
      loadingListagem: false,
      disableRelatorio: false
    };
  },
  mounted() {
    this.getRelatorios();
  },
  methods: {
    copy (codigo=null, urlCompleta) {
      navigator.clipboard.writeText(urlCompleta ? `${window.location.origin}/relatorio?codigo=${codigo}` : codigo);
    },
    getRelatorios() {
      this.loadingListagem = true;
      Api.get("relatorios")
        .then((res) => {
          this.relatorios = res.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        });
    },
    gerarRelatorio(uuid) {
      this.disableRelatorio = true;
      Api.getBlob(`relatorios/pdf?rel_uuid=${uuid}`)
        .then(res => {
          toast.success('Relatório gerado!');
          let arquivoNome = 'Relatório_' + moment().format('L_H:mm') + '.pdf';
          var arquivoURL = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
          var arquivoLink = document.createElement('a');
          arquivoLink.href = arquivoURL;
          arquivoLink.setAttribute('download', arquivoNome);
          document.body.appendChild(arquivoLink);
          arquivoLink.click();
        })
        .catch(err => {
          toast.error(err.response.data.message)
        })
        .finally(() => {
          this.disableRelatorio = false;
        })
    }
  },
};
</script>
