<template>
  <v-container>
    <v-row no-gutters>
      <span class="text-h4">Ingressos - {{ evento.eve_titulo }}</span>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10">
        <v-form ref="formEvento" @submit.prevent="saveIngresso">
          <v-row dense>
            <v-switch
              v-model="ingresso.evi_ativo"
              hide-details
              color="primary"
              :true-value="1"
              :false-value="0"
              label="Ativo"
            ></v-switch>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                type="text"
                :rules="rules.getMandatoryRule()"
                label="Nome*"
                v-model="ingresso.evi_nome"
                :loading="loadingCadastro"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                type="tel"
                label="Quantidade*"
                v-model="ingresso.evi_quantidade"
                :rules="rules.getQuantityRule()"
                v-mask-number
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                density="compact"
                bg-color="white"
                variant="outlined"
                type="text"
                label="Valor*"
                :loading="loadingCadastro"
                v-model="evi_valor"
                v-mask-decimal.br="2"
                prefix="R$"
                :rules="rules.getMonetaryRule(5)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="mb-6">
            <v-col cols="12" sm="12" md="12">
              <VueDatePicker 
                :format="format"
                partial-flow 
                :flow="['calendar', 'time']"
                v-model="evi_data"
                :teleport="true"
                placeholder="Selecione a data"
                select-text="Selecionar"
                cancel-text="Cancelar"
                :clearable="false"
                :action-row="{ showPreview: false }"
                range
                multi-calendars
              >
              </VueDatePicker>
            </v-col>
          </v-row>
          <div class="mt-3">
            <v-btn rounded="xl" class="blue-background text-white px-4" type="submit" :disabled="disableFields || loadingCadastro">salvar</v-btn>
            <v-btn rounded="xl" class="ml-2" @click="resetFields()">Limpar campos</v-btn>
          </div>
        </v-form>
        
      </v-col>
      <v-col>
        <v-btn 
          rounded="xl" 
          class="blue-background text-white mb-2" 
          block
          :to="`/eventos/cadastro/${$route.params.eve_uuid}`"
        >
          Detalhes
        </v-btn>
        <v-btn
          rounded="xl"
          class="blue-background text-white"
          block
          :to="`/eventos/vendas/${this.$route.params.eve_uuid}`"
        >
          Vendas
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
  <v-row dense class="mt-3 mx-6">
    <v-col>
      <v-table>
        <thead>
          <tr>
            <th class="text-left">
              Status
            </th>
            <th class="text-left">
              Nome
            </th>
            <th class="text-center">
              Quantidade
            </th>
            <th class="text-center">
              Valor
            </th>
            <th class="text-center">
              Data início
            </th>
            <th class="text-center">
              Data fim
            </th>
            <th class="text-center">
              Ação
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
          v-for="(item, index) in ingressos"
          :key="index"
          >
            <td>{{ item.evi_ativo ? 'Ativo' : 'Inativo'}}</td>
            <td>{{ item.evi_nome }}</td>
            <td class="text-center">{{item.evi_quantidade_disponivel}}/{{ item.evi_quantidade }}</td>
            <td class="text-center">{{ formatters.floatToMoney(item.evi_valor) }}</td>
            <td class="text-center">{{ formatters.formatDate(item.evi_data_inicio, 'LLL') }}</td>
            <td class="text-center">{{ formatters.formatDate(item.evi_data_fim, 'LLL') }}</td>
            <td class="text-center">
              <v-icon @click="editIngresso(item)" class="mr-1">
                mdi-pencil
              </v-icon>
              <v-icon @click="abreDialog(item.evi_uuid)">
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </tbody>
      </v-table>
      <v-row justify="center" dense class="mt-3" v-if="!ingressos.length">
        <span>Nenhum ingresso cadastrado</span>
      </v-row>
    </v-col>
  </v-row>
  <delete-card :titleCard="'este ingresso'" :abrirDialog="dialogDelete" :key="keyDelete" @fecharDialogDelete="fecharDialog" @confirmDelete="deleteEvento"></delete-card>
</template>

<script>
import Api from '@/helpers/api';
import Formatters from '@/helpers/formatters';
import Rules from '@/helpers/rules';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import DeleteCard from '@/components/DeleteCard.vue';

export default {
  components: {
    VueDatePicker,
    DeleteCard
  },
  data() {
    return {
      rules: Rules,
      formatters: Formatters,
      disableFields: false,
      dialogDelete: false,
      keyDelete: 0,
      loadingCadastro: false,
      evento: [],
      evi_data: [],
      evi_valor: null,
      ingressos: [],
      ingresso: {
        evi_nome: null,
        evi_ativo: 1,
        evi_quantidade: null,
        evi_valor: null,
        evi_data_inicio: null,
        evi_data_fim: null,
        eve_uuid: null
      },

      format: (date) => {
        let dataInicio = Formatters.formatDate(date[0], 'DD/MM/YYYY HH:mm')
        let dataFim = Formatters.formatDate(date[1], 'DD/MM/YYYY HH:mm')
        return [dataInicio, dataFim];
      }
    }
  },
  mounted () {
    this.getEvento();
    this.getIngressos();
  },
  methods: {
    getEvento () {
      if (!this.$route.params.eve_uuid) return
      this.loadingCadastro = true
      Api.post('eventos/buscar', { eve_uuid: this.$route.params.eve_uuid })
        .then(res => {
          this.evento = res.data
          this.ingresso.eve_uuid = this.evento.eve_uuid;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCadastro = false;
        })
    },
    getIngressos () {
      if (!this.$route.params.eve_uuid) return
      this.loadingCadastro = true
      Api.get('eventos/ingressos/' + this.$route.params.eve_uuid)
        .then(res => {
          this.ingressos = res.data
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCadastro = false;
        })
    },
    editIngresso (item) {
      this.evi_data = [item.evi_data_inicio, item.evi_data_fim];
      this.evi_valor = Formatters.floatToMoney(item.evi_valor);
      this.ingresso = {
        evi_uuid: item.evi_uuid,
        evi_nome: item.evi_nome,
        evi_ativo: item.evi_ativo,
        evi_quantidade: item.evi_quantidade,
        evi_valor: null,
        evi_data_inicio: null,
        evi_data_fim: null,
        eve_uuid: this.evento.eve_uuid
      }
    },
    async saveIngresso (event) {
      this.disableFields = true;
      const results = await event
      if (!results.valid) {
        this.disableFields = false;
        toast.info("Preencha todos os campos!", {
          position: `bottom-center`
        })
        return
      }
      if (!this.evi_data.length) {
        this.disableFields = false;
        toast.info("Ingresso precisa ter as datas!", {
          position: `bottom-center`
        })
        return
      }
      this.ingresso.evi_data_inicio = Formatters.formatDateToEn(this.evi_data[0], "DD/MM/YYYY HH:mm", "YYYY-MM-DD  HH:mm");
      this.ingresso.evi_data_fim = Formatters.formatDateToEn(this.evi_data[1], "DD/MM/YYYY HH:mm", "YYYY-MM-DD  HH:mm");
      this.ingresso.evi_valor = Formatters.moneyToFloat(this.evi_valor);
      let notificacao = toast.loading("Salvando ingresso");

      let rota = 'novo';
      if (this.ingresso?.evi_uuid) rota = 'editar'

      Api.post(`eventos/ingressos/${rota}`, this.ingresso)
        .then(res => {
          toast.update(notificacao, {
            render: res.data.message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
          });
          this.resetFields();
          this.getIngressos();
        })
        .catch(err => {
          toast.update(notificacao, {
            render: err.response.data.message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
          })
        }).finally(() =>{
          this.disableFields = false;
        })
    },
    resetFields() {
      this.evi_data = [];
      this.evi_valor = null;
      this.ingresso = {
        evi_nome: null,
        evi_ativo: 1,
        evi_quantidade: null,
        evi_valor: null,
        evi_data_inicio: null,
        evi_data_fim: null,
        eve_uuid: this.evento.eve_uuid
      }
    },
    fecharDialog () {
      this.getIngressos();
      this.dialogDelete = false;
    },
    abreDialog (uuid) {
      this.evi_uuid = uuid;
      this.dialogDelete = true;
      this.keyDelete++
    },
    deleteEvento () {
      if (!this.evi_uuid) return
      Api.post('eventos/ingressos/excluir', { evi_uuid: this.evi_uuid })
        .then(res => {
          toast.success(res.data.message)
        })
        .catch(err => {
          toast.error(err?.response?.data?.message)
        })
        .finally(() => {
          this.fecharDialog();
        })
    },
  }
}
</script>