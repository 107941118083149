import Cookies from "js-cookie";
import Bcrypt from 'bcryptjs';

class Login {
    static isLoggedIn () {
        const auth = Cookies.get('auth_token');
        return !!auth;
    }

    static saveToken (token, user_type) {
        Cookies.set('auth_token', token, { expires: 6/24 }); // 6 horas para expirar
        let hash = Bcrypt.hashSync(user_type, 10);
        Cookies.set('type_token', hash, { expires: 6/24 }); // 6 horas para expirar
    }

    static verifyUserType (user_type) {
        if (!this.isLoggedIn()) return false;
        const hash = Cookies.get('type_token');
        if (hash) return Bcrypt.compareSync(user_type, hash);
        return false;
    }
}

export default Login