<template>
  <v-container fluid>
    <v-row no-gutters>
      <span class="text-h4">Eventos</span>
      <v-spacer></v-spacer>
      <v-btn rounded="xl" class="blue-background text-white" @click="actions()">Novo Evento</v-btn>
    </v-row>
    <v-row class="mt-1">
      <v-col>
        <eventos-listagem @actions="actions" :key="keyListagem"></eventos-listagem>
      </v-col>
    </v-row>
  </v-container>
  <delete-card :titleCard="'este evento'" :abrirDialog="dialogDelete" :key="keyDelete" @fecharDialogDelete="fecharDialog" @confirmDelete="deleteEvento"></delete-card>
</template>

<script>
import EventosListagem from '@/components/eventos/EventosListagem.vue';
import DeleteCard from '@/components/DeleteCard.vue';
import Api from '@/helpers/api';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  components: {
    EventosListagem,
    DeleteCard
  },
  data() {
    return {
      dialogCadastro: false,
      dialogDelete: false,
      eve_uuid: null,
      keyListagem: 0,
      keyDelete: 0
    }
  },
  methods: {
    deleteEvento () {
      if (!this.eve_uuid) return
      Api.post('eventos/excluir', { eve_uuid: this.eve_uuid })
        .then(res => {
          toast.success(res.data.message)
        })
        .catch(err => {
          toast.error(err?.response?.data?.message)
        })
        .finally(() => {
          this.fecharDialog();
        })
    },
    fecharDialog () {
      this.keyListagem++;
      this.eve_uuid = null;
      this.eve_nome = null;
      this.dialogDelete = false;
    },
    actions (action='create', uuid=null) {
      this.eve_uuid = uuid;
      if (action == 'delete') {
        this.dialogDelete = true;
        this.keyDelete++
        return
      }
      if (action == 'copy') {
        toast.success("Link copiado com sucesso!")
        navigator.clipboard.writeText(`${window.location.origin}/eventos/compras/detalhes/${uuid}`);
        return
      }
      this.$router.push('/eventos/cadastro' + (uuid ? `/${uuid}` : ''));
    }
  }
}
</script>
