<template>
  <v-container>
    <v-row no-gutters>
      <span class="text-h4">Vendas - {{ evento.eve_titulo }}</span>
    </v-row>
    <v-row no-gutters class="mt-4">
      <v-col cols="12" sm="12" md="10">
        <v-expansion-panels variant="inset">
          <v-expansion-panel v-for="(item, index) in vendas" :key="index">
            <v-expansion-panel-title>
              <v-row no-gutters align="center">
                <v-col>
                  <!-- COMPRA CONFIRMADA-->
                  <v-chip color="green" v-if="item.evc_confirmado == 2">
                    <v-icon color="green" icon="mdi-check" class="mr-2"></v-icon>
                    <span class="text-black">Confirmado</span>
                  </v-chip>
                  <!-- COMPRA PENDENTE-->
                  <v-chip color="orange" v-if="item.evc_confirmado == 1">
                    <v-icon color="orange" icon="mdi-alert" class="mr-2"></v-icon>
                    <span class="text-black">Pendente</span>
                  </v-chip>
                  <!-- COMPRA VENCIDA-->
                  <v-chip color="red" v-if="item.evc_confirmado == 0">
                    <v-icon color="red" icon="mdi-close" class="mr-2"></v-icon>
                    <span class="text-black">Vencido</span>
                  </v-chip>
                </v-col>
                <v-col>{{ item.usuario.usu_nome }}</v-col>
                <v-col class="text-center">{{ formatters.floatToMoney(item.evc_valor) }}</v-col>
                <v-col class="text-center">{{ formatters.formatDate(item.created_at) }}</v-col>
                <v-col class="text-right pr-2">
                  <v-tooltip text="Abrir cobrança" location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn icon flat density="comfortable" :href="item.asaas_url" target="_blank">
                        <v-icon v-bind="props" icon="mdi-cash"></v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row class="text-overline">
                <v-col>Ingresso</v-col>
                <v-col>Código</v-col>
              </v-row>
              <v-row v-for="(ing, i) in item.ingressos" :key="i">
                <v-col>{{ ing.evi_nome }}</v-col>
                <v-col>{{ ing.evp_codigo }}</v-col>
                <v-divider v-if="item.ingressos.length-1 > i"></v-divider>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col>
        <v-btn rounded="xl" class="blue-background text-white mb-2" block
          :to="`/eventos/cadastro/${$route.params.eve_uuid}`">
          Detalhes
        </v-btn>
        <v-btn rounded="xl" class="blue-background text-white" block
          :to="`/eventos/ingressos/${this.$route.params.eve_uuid}`">
          Ingressos
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from '@/helpers/api';
import Formatters from '@/helpers/formatters';
export default {
  data() {
    return {
      formatters: Formatters,
      evento: [],
      vendas: [],
      loadingListagem: false
    }
  },
  mounted() {
    this.getEvento();
    this.getVendas();
  },
  methods: {
    getEvento() {
      if (!this.$route.params.eve_uuid) return
      this.loadingListagem = true
      Api.post('eventos/buscar', { eve_uuid: this.$route.params.eve_uuid })
        .then(res => {
          this.evento = res.data
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        })
    },
    getVendas() {
      if (!this.$route.params.eve_uuid) return
      this.loadingListagem = true
      Api.get('eventos/vendas/' + this.$route.params.eve_uuid)
        .then(res => {
          this.vendas = res.data
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingListagem = false;
        })
    },
    openDetalhes(index) {
      this.indexVenda = index;
      this.dialogVendas = true
    }
  }
}
</script>