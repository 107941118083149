<template>
  <v-app>
    <div v-if="showNavBar()">
      <v-app-bar elevation="1" color="#FFBA00">
        <v-app-bar-title>
          <v-row no-gutters align="center">
            <v-app-bar-nav-icon class="d-flex d-none d-md-flex d-lg-none" @click="drawer = !drawer"></v-app-bar-nav-icon>
            <img :src="require('./assets/ndpz_logo.svg')" height="45" class="ml-5"/>
          </v-row>
        </v-app-bar-title>
      </v-app-bar>
      <v-navigation-drawer :expand-on-hover="!this.$vuetify.display.mobile" :rail="!this.$vuetify.display.mobile" v-model="drawer">
        <v-list density="compact" nav active-class="blue-color">
          <v-list-item title="Lançamentos" prepend-icon="mdi-cash-register" value="lancamentos" to="/lancamentos"></v-list-item>
          <v-list-item title="Clientes" prepend-icon="mdi-account-group" value="clientes" to="/clientes"></v-list-item>
          <v-list-item title="Categorias" prepend-icon="mdi-format-list-bulleted" value="categorias" to="/categorias"></v-list-item>
          <v-list-item title="Carteiras" prepend-icon="mdi-wallet" value="carteiras" to="/carteiras"></v-list-item>
          <v-list-item title="Eventos" prepend-icon="mdi-calendar" value="eventos" to="/eventos"></v-list-item>
        </v-list>
        <template v-slot:append>
          <div class="py-2 px-1" >
            <v-btn block variant="outlined" class="pa-2" @click="logout()">
              Sair
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Cookie from 'js-cookie';
import Api from './helpers/api';
export default {
  data () {
    return {
      expandOnHover: true,
      drawer: true
    }
  },
  methods: {
    showNavBar () {
      return this.$route.meta.requiresAuth;
    },
    async logout() {
      await Api.post("usuarios/logout")
        .then(response => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(()=> {
          Cookie.remove('auth_token');
          Cookie.remove('type_token');
          this.$router.go('Login');
        })
    },
  },
}
</script>

<style lang="scss">
@import "./assets/scss/_variables.sass";

.blue-color {
  color: $blue !important;
}
.yellow-color {
  color: $yellow !important;
}
.white-color {
  color: white !important;
}
.blue-background {
  background-color: $blue !important;
}
.yellow-background {
  background-color: $yellow !important;
}
</style>
